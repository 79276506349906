import React from 'react'
// import OwlCarousel from 'react-owl-carousel2'
import Swiper from 'react-id-swiper'

function GallerySlider (props) {
  // const options = {
  //   responsive: {
  //     0: {
  //       items: 2,
  //       autoWidth: false,
  //       margin: 200,
  //       center: true,
  //       // autoHeight: true,

  //     },
  //     1024: {
  //       autoHeight: true,
  //       autoHeightClass: 'owl-height',
  //       autoWidth: true,
  //       items: 5,
  //       center: false,
  //       loop: false,
  //       margin: 20
  //     }
  //   }
  // }

  // const events = {}

  const params = {
    slidesPerView: '3',
    spaceBetween: 20,
    breakpoints: {}
  }

  return (
    <div
      style={
        {
          // width: 'calc(100% + 40px)',
          // marginLeft: -20
        }
      }
    >
      <Swiper {...params}>
        {props.medias.map((media, key) => (
          // <div key={key}>
          <img
            style={{ maxWidth: 450 }}
            key={key}
            src={media.image}
            alt={`media${key}`}
          />
          // </div>
        ))}
      </Swiper>
    </div>
  )
}

export default GallerySlider
