import React from 'react'
import { ModalContext } from '../../context/ModalContext'
import { NotFound } from '../not-found/NotFound'

function Modal (props) {
  const [dataModal, setDataModal] = React.useContext(ModalContext)

  const FinalComponent = dataModal.data.component
    ? dataModal.data.component
    : NotFound

  return (
    <div className='modal-wrapper'>
      <div
        className='backdrop'
        onClick={() => setDataModal({ ...dataModal, display: false })}
      />
      <div className='modal'>
        <header> {dataModal.data.title} </header>
        <div className='modal-content'>
          <FinalComponent
            authContext={props.data.authContext}
            {...dataModal.data}
          />
        </div>
      </div>
    </div>
  )
}

export default Modal
