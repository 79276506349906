import React from 'react'
import loopIcoon from '../../assets/images/icon_loop.svg'
import moneyIcon from '../../assets/images/icon_euro.svg'
import clockIcon from '../../assets/images/icon_clock.svg'
import {useMediaQuery} from 'react-responsive'
import {Link} from 'react-router-dom'
import ArrowLink from '../../assets/images/icon_arrow_2.svg'
import CurvedArrow from '../../assets/images/curved_arrow_1.svg'

function BlockHowItWork (props) {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  });

  return (
    <div style={props.style}>
      <section className='rounded-bubble home full-width'>
        <h2 style={{ textAlign: 'center' }}>
          Comment{' '}
          <span style={{ color: '#D0CDE4', marginLeft: -10 }}>ÇA MARCHE ?</span>
        </h2>
        <div className='how-it-work flex-column'>
          <span>
            <b className={"step-number"}>1</b>
            Choisissez une expérience
            { isDesktop && <img src={CurvedArrow} alt='arrow' /> }
          </span>
          <span>
            <b className={"step-number"}>2</b>
            Jouez pour quelques euros
            { isDesktop && <img src={CurvedArrow} alt='arrow' /> }
          </span>
          <span>
            <b className={"step-number"}>3</b>
            Un seul gagnant par expérience{' '}
          </span>
        </div>
        <Link to='/page/comment-ca-marche' className='link-reglement'>
          VOIR LE RÈGLEMENT
        </Link>
      </section>
    </div>
  )
}

export default BlockHowItWork
