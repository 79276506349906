import initialState from "./initialState";
import * as actions from './app.actions';

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.REQUEST_USER: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actions.FETCH_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                user: {
                    'profile' : action.user[0],
                    'challenges' : action.user[1],
                }
            }
        }
        case actions.FETCH_USER_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }
        }
        case actions.SET_REDIRECT_GAME: {
            return {
                ...state,
                redirectGame: action.url
            }
        }
        case actions.FETCH_MENU_SUCCESS: {
            return {
                ...state,
                menu: action.menu
            }
        }
        default: {
            return state
        }
    }
}