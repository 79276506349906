import React from 'react'
import clock from '../../assets/images/icon_clock.svg'
import euro from '../../assets/images/icon_euro.svg'
import moment from 'moment'
import { useInterval } from '../../hooks/useInterval'
// import cx from 'classnames/bind';

function PriceTime (props) {
  const [timeLeft, setTimeLeft] = React.useState(null)

  useInterval(() => {
    renderTimeLeft()
  }, 60000)

  React.useEffect(
    () => {
      renderTimeLeft()

      return () => {}
    },
    [timeLeft]
  )

  const renderTimeLeft = () => {
    const start = moment(Date.now())
    const end = moment.unix(props.time)

    const days = end.diff(start, 'days')
    const hours = end.diff(start, 'hours')
    const minutes = end.diff(start, 'minutes')

    if (days >= 1) {
      setTimeLeft(
        `${Math.round(days)} ${Math.round(days) === 1 ? `jour` : 'jours'} `
      )
    } else if (hours >= 1) {
      setTimeLeft(
        `${Math.round(hours)} ${Math.round(hours) === 1 ? `heure` : 'heures'} `
      )
    } else {
      setTimeLeft(
        `${Math.round(minutes)} ${
          Math.round(minutes) === 1 ? `minute` : 'minutes'
        } `
      )
    }
  }

  return (
    <div
      className='infos'
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <span className='days-left'>
        <img src={clock} alt='clock' />
        {props.isPassed
          ? 'Expérience terminée'
          : props.now
            ? `IL RESTE ${timeLeft}`
            : `Débute dans ${timeLeft}`}
      </span>
      {!props.isPassed && (
        <span className='days-left'>
          <img src={euro} alt='price' />
          Pour {props.startPrice}&euro;
        </span>
      )}
    </div>
  )
}

export default PriceTime
