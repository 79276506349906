import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import { AuthContext } from '../../context/AuthContext';
// import { AUTH_METHOD } from '../../auth/base';
// import { routes } from '../../routing/routes';
// import LocalStorageInstance from '../../helpers/LocalStorage';
import BurgerIcon from './BurgerIcon'
import MenuMobile from '../shared/MenuMobile'
import Logo from '../../assets/images/logo.svg'
import { withRouter } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import DesktopMenu from '../shared/DesktopMenu'

function Header (props) {
  // const authContext = useContext(AuthContext)

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const MenuMobileState = open => {
    setShowMobileMenu(open)
  }

  React.useEffect(
    () => {
      if (showMobileMenu) {
        document.querySelector('#nav-icon') &&
          document.querySelector('#nav-icon').click()
      }

      return () => {}
    },
    [props.location]
  )

  const isDesktop = useMediaQuery({
    query: '(min-width: 1280px)'
  })

  return (
    <>
      <header className='main-header'>
        <div className='wrapper'>
          <span className='brand'>
            <Link to='/'>
              <img
                src={Logo}
                alt='logo'
                style={{ width: isDesktop ? 242 : 200 }}
              />
            </Link>
          </span>
          {isDesktop ? (
            <DesktopMenu />
          ) : (
            <BurgerIcon
              open={showMobileMenu}
              onPress={open => MenuMobileState(open)}
            />
          )}
        </div>
      </header>
      {showMobileMenu && !isDesktop && <MenuMobile />}
    </>
  )
}

export default withRouter(Header)
