import React, { useState, useContext, useEffect } from 'react'
import cx from 'classnames/bind'
import { AuthContext } from '../../../context/AuthContext'
import Register from '../../auth/components/Register'
import { connect } from 'react-redux'
import Checkout from './Checkout'
import { withRouter, Redirect } from 'react-router-dom'
// import LocalStorageInstance from '../../../helpers/LocalStorage';
import '../index'
import { fetchChallenge } from '../store/challenge.actions'
import LocalStorageInstance from '../../../helpers/LocalStorage'
// import LocalStorageInstance from '../../../helpers/LocalStorage';

function Payment (props) {
  const [currentStep, setCurrentStep] = useState(0)
  const [ContentComponent, setContentComponent] = useState(null)
  const context = useContext(AuthContext)

  const steps = [
    {
      label: 'Inscription',
      component: !context.isAuth ? (
        <Register
          onRegistered={() => onRegistered()}
          edit={false}
          from='payment'
          authContext={context}
        />
      ) : null,
      forbid: context.isAuth,
      valid: false
    },
    {
      label: 'Paiement',
      component: <Checkout challengeId={props.match.params.challengeId} />,
      forbid: false,
      valid: false
    }
  ]

  useEffect(() => {
    props.fetchChallenge(props.match.params.challengeId)
    LocalStorageInstance.store('gameId', props.match.params.challengeId)
    return () => {
      // LocalStorageInstance.remove('gameId')
    }
  }, [])

  const onRegistered = () => {
    getToStep(currentStep + 1)
  }

  useEffect(
    () => {
      if (context.isAuth) {
        setCurrentStep(1)
        setContentComponent(steps[1]['component'])
      } else {
        setContentComponent(steps[currentStep]['component'])
      }

      return () => {}
    },
    [context]
  )

  useEffect(
    () => {
      setContentComponent(steps[currentStep]['component'])

      return () => {}
    },
    [currentStep]
  )

  const getToStep = index => {
    if (currentStep !== index) {
      if (!steps[index].forbid && steps[index - 1].valid) {
        setCurrentStep(index)
      }
    }
  }

  const query = new URLSearchParams(props.location.search)

  return !context.isAuth && !query.has('step') ? (
    <Redirect to='/auth/login' />
  ) : (
    <div className='checkout'>
      <div className='steps-container'>
        {steps.map((step, index) => (
          <div
            key={index}
            className={cx({ step: true, active: index === currentStep })}
            onClick={() => getToStep(index)}
          >
            <i> {index + 1}</i>
            <span> {step.label} </span>
          </div>
        ))}
      </div>
      <div className='content'>
        <div className='wrapper-content center'>{ContentComponent || null}</div>
      </div>
    </div>
  )
}

export default withRouter(
  connect(
    null,
    { fetchChallenge }
  )(Payment)
)
