import React from 'react'
import cx from 'classnames/bind'
import TagManager from 'react-gtm-module'

function PopupProcessPayement (props) {
  React.useEffect(() => {
    if (props.paymentStatus && props.paymentStatus.error === false) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'dataPushed',
          ecommerce: {
            purchase: {
              actionField: {
                id:
                  props.paymentStatus.paymentId &&
                  props.paymentStatus.paymentId,
                revenue:
                  props.challenge.price &&
                  props.challenge.price.toFixed(2).toString()
              },
              products: [
                {
                  name: props.challenge.title && props.challenge.title,
                  quantity: 1,
                  price:
                    props.challenge.price &&
                    props.challenge.price.toFixed(2).toString()
                }
              ]
            }
          }
        }
        // dataLayerName: 'PageDataLayer'
      }
      TagManager.dataLayer(tagManagerArgs)
    }

    return () => {}
  }, [props])

  return (
    <div
      className={cx({
        'payment-status': true,
        submitted:
          props.paymentStatus && props.paymentStatus.status === 'submitted',
        error: props.paymentStatus && props.paymentStatus.error
      })}
    >
      <div
        className={cx({
          icon: true,
          submitted:
            props.paymentStatus && props.paymentStatus.status === 'submitted',
          error: props.paymentStatus && props.paymentStatus.error
        })}
      >
        <svg
          width='84px'
          height='84px'
          viewBox='0 0 84 84'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          xlink='http://www.w3.org/1999/xlink'
        >
          <circle
            className='border'
            cx='42'
            cy='42'
            r='40'
            strokeLinecap='round'
            strokeWidth='4'
            stroke='#000'
            fill='none'
          />
          {props.paymentStatus && props.paymentStatus.error ? (
            <path
              style={{ transform: 'translateX(17px) translateY(16px)' }}
              className='checkmark'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='4'
              stroke='#000'
              fill='none'
              d='M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z'
            />
          ) : (
            <path
              className='checkmark'
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338'
              strokeWidth='4'
              stroke='#000'
              fill='none'
            />
          )}
        </svg>
      </div>
      {props.paymentStatus && props.paymentStatus.status === 'submitted' && (
        <>
          <h3 className='title' data-tid='elements_examples.success.title'>
            {props.paymentStatus && props.paymentStatus.error
              ? 'Paiement échoué'
              : 'Paiement réussi'}
          </h3>
          <p className='message' style={{ marginTop: 20 }}>
            {props.paymentStatus.message}
          </p>
          {props.paymentStatus.error ? (
            <button className='btn-play' onClick={() => props.retry()}>
              Quitter
            </button>
          ) : (
            <button className='btn-play' onClick={() => props.play()}>
              Jouer
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default PopupProcessPayement
