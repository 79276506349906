import React from 'react'
import cx from 'classnames/bind'
import * as scroll from 'react-scroll'

function LimitedContent (props) {
  const [reduce, setReduce] = React.useState(true)

  React.useEffect(
    () => {
      if (reduce === true) {
        scroll.animateScroll.scrollTo(
          document.querySelector('.wrapper-content'),
          {
            duration: 0,
            delay: 0,
            smooth: false,
            offset: 50 // Scrolls to element + 50 pixels down the page
          }
        )
      }
      return () => {}
    },
    [reduce]
  )

  return (
    <>
      <section
        className={cx({
          content: true,
          reduced: reduce
        })}
        dangerouslySetInnerHTML={{ __html: props && props.content }}
      />
      {
        <span
          onClick={() => setReduce(!reduce)}
          className='content_reducer_action'
        >
          {reduce ? 'JE VEUX VOIR TOUS LES DÉTAILS' : 'Cacher les détails'}
        </span>
      }
    </>
  )
}

export default LimitedContent
