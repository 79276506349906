import React from 'react'
import { NavLink } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { connect } from 'react-redux'
import { AppMenuSelector } from '../../stores/app/app.selectors'
import cx from 'classnames/bind'
// import ArrowDown from '../../assets/images/icon_drop.svg'

// import slugify from 'slugify'

function DesktopMenu (props) {
  const context = React.useContext(AuthContext)
  const [indexSubmenuOpen] = React.useState(null)

  // const toggleSubmenu = index => {
  //   indexSubmenuOpen === index
  //     ? setIndexSubmenuOpen(null)
  //     : setIndexSubmenuOpen(index)
  // }

  const renderArrowSubmenu = (color = '#4c428e') => {
    return (
      <svg
        width='15'
        height='9'
        viewBox='0 0 15 9'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.50003 6.00003L1.5 0L0 1.5L7.4942 8.99419L7.91276 8.57563L7.91859 8.58147L14.9999 1.50011L13.4999 0.000116584L7.50003 6.00003Z'
          fill={color}
        />
      </svg>
    )
  }

  return (
    <nav id='menu-wrapper'>
      <ul>
        {props.menuItems.map((item, index) => (
          <li
            key={index}
            className={cx({
              menu__item: true,
              hasChhild: item.childs,
              isOpen: indexSubmenuOpen === index
            })}
          >
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              {item.href ? (
                <NavLink exact activeClassName='active' to={item.href}>
                  {item.label}
                </NavLink>
              ) : (
                item.label
              )}
              {item.childs && renderArrowSubmenu()}
            </span>

            {item.childs && (
              <div
                className={cx({
                  submenu: true,
                  open: indexSubmenuOpen === index
                })}
              >
                <ul>
                  {item.childs.map((child, index2) => (
                    <li key={index2} className='menu__item'>
                      <NavLink activeClassName='active' to={child.href}>
                        {child.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
        <li
          className={cx({
            menu__item: true,
            login: true
          })}
        >
          <NavLink
            activeClassName='active'
            to={context.isAuth ? '/espace-personnel' : '/auth/login'}
          >
            {context.isAuth ? 'Espace personnel' : 'Se connecter'}
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default connect(
  state => ({
    menuItems: AppMenuSelector(state)
  }),
  null
)(DesktopMenu)
