import React from 'react'

function SectionTitle(props) {
    return (
        <div className="wrapper-content center">
            <h2 className="section-title-sub">{props.title}<span>{props.subtitle}</span></h2>
        </div>
    )
}

export default SectionTitle
