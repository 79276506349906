import { Home } from '../components'
// import LazyTodo from "../features/todos/Lazy";
import Auth from '../features/auth'
import LazyChallenge from '../features/challenge/Lazy'
import LazyAssociations from '../features/association/Lazy'
import LazyPages from '../features/pages/Lazy'
// import Account from "../components/account";
import Play from '../features/challenge/components/Play'
import Payment from '../features/challenge/components/Payment'
// import Contact from '../components/shared/Contact'
import LazyProfile from '../features/profile/Lazy'
import LazyContact from '../features/contact/LazyContact'
import ResetPassword from '../features/auth/components/ResetPassword'
import ServerError from '../components/utils/ServerError'

export const routes = [
  {
    path: '/',
    private: false,
    component: Home,
    exact: true,
    label: "home"
  },
  {
    path: '/auth/:action',
    private: false,
    component: Auth,
    exact: true,
    label: "auth"
  },
  {
    path: '/challenge/:slug/:id',
    private: false,
    component: LazyChallenge,
    exact: true,
    label: "challenge"
  },
  {
    path: '/associations/:slug/:id',
    private: false,
    component: LazyAssociations,
    exact: true,
    label: "associations"
  },
  {
    path: '/espace-personnel',
    private: true,
    component: LazyProfile,
    exact: true,
    label: "profile"
  },
  {
    path: '/espace-personnel/:tab',
    private: true,
    component: LazyProfile,
    exact: true,
    label: "profile-tab"
  },
  {
    path: '/play/:challengeId/',
    private: false,
    component: Play,
    exact: true,
    label: "play"
  },
  {
    path: '/payment/:challengeId',
    private: false,
    component: Payment,
    exact: true,
    label: "payment-challenge"
  },
  {
    path: '/contact',
    private: false,
    component: LazyContact,
    exact: true,
    label: "contact"
  },
  {
    path: '/reset-password',
    private: false,
    component: ResetPassword,
    exact: true,
    label: "reset-password"
  },
  {
    path: '/page/:slug',
    private: false,
    component: LazyPages,
    exact: true,
    label: "page"
  },
  {
    path: '/error/:code',
    private: false,
    component: ServerError,
    exact: true,
    label: "error"
  }
]
