import apiService from "../../conf/api.custom";
import axios from "axios";
import UserService from "../../api/services/UserService";
import {fetchUserSucess} from "../app/app.actions";

export const REQUEST_HOME = 'request home';
export const FETCH_HOME = 'fetch home';
export const FETCH_HOME_SUCCESS = 'fetch home success';
export const FETCH_HOME_ERROR = 'fetch home error';

export const requestHome = () => ({
    type: REQUEST_HOME,
});

export const fetchHomeSucess = home => ({
    type: FETCH_HOME_SUCCESS,
    home
});

export const fetchHomeError = error => ({
    type: FETCH_HOME_ERROR,
    error
});

export const fetchHome = () => dispatch => {
    dispatch( requestHome() );
    return axios.all([apiService.get( '/home' ),apiService.get( '/option/banner' )]).then(
        axios.spread((home, banner) => {
            var data = {};
            Object.assign(data, home.data, {"banner": banner.data})
            dispatch(fetchHomeSucess(data));
        })
    ).catch((err) => {
        dispatch(fetchHomeError(err))
    })
}
