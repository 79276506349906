import { createSelector } from 'reselect';

export const appSelector = state => state.app;

export const appIsLoadingSelector = createSelector(
    [ appSelector ],
    app => app.isLoading
)

export const AppUserSelector = createSelector(
    [appSelector],
    app => app.user
)

export const AppUserProfileSelector = createSelector(
    [appSelector],
    app => app.user && app.user.profile
)

export const AppGetRedirectGame = createSelector(
    [appSelector],
    app => app.redirectGame
)

export const AppMenuSelector = createSelector(
    [appSelector],
    app => app.menu
)