import { createSelector } from 'reselect';

export const homeSelector = state => state.home;

export const homeIsLoadingSelector = createSelector(
    [ homeSelector ],
    home => home.isLoading
)

export const HomeDataSelector = createSelector(
    [homeSelector],
    home => home.data
)