import React from 'react'
import ChallengesService from '../../../api/services/ChallengesService'
import cx from 'classnames/bind'
import { Redirect } from 'react-router-dom'
import * as scroll from 'react-scroll'
import { connect } from 'react-redux'
import {
  challengeDataSelector,
  participateDataSelector
} from '../store/challenge.selectors'
import { fetchUser } from '../../../stores/actions'
import { AppUserProfileSelector } from '../../../stores/selectors'
// import CustomCardElement from './CustomCardElement'
import PopupProcessPayement from './PopupProcessPayement'
import slugify from 'slugify'
import Paymill from './Paymill'
// import TagManager from 'react-gtm-module'

function Checkout (props) {
  // console.log(mollie)
  const [challenge, setChallenge] = React.useState(null)
  const [canProceed, setCanProceed] = React.useState(true)
  const [inputValue, setInputValue] = React.useState(0)
  const [error, setError] = React.useState(null)
  const [paymentOk, setPaymentOk] = React.useState(false)
  const [paymentStatus, setPaymentStatus] = React.useState(null)
  const [processPayment, setProcessPayment] = React.useState(false)
  const [errorPay, setErrorPay] = React.useState(null)

  const amountRef = React.useRef()

  React.useEffect(() => {
    scroll.animateScroll.scrollToTop()

    return () => {}
  }, [])

  React.useEffect(() => {
    if (props.challenge) {
      props.fetchUser().then(() => setChallenge(props.challenge))
    }

    return () => {}
  }, [props.challenge])

  React.useEffect(() => {
    if (challenge) {
      setInputValue(parseInt(props.challenge.price))
    }

    return () => {}
  }, [challenge])

  React.useEffect(() => {
    if (challenge && parseInt(inputValue) >= parseInt(challenge.price)) {
      setCanProceed(true)
      setError(null)
    } else {
      setCanProceed(false)
      setError('Montant trop faible')
    }

    return () => {}
  }, [inputValue])

  function onErrorPay (error) {
    setErrorPay(error)
  }
  function tryPay (token) {
    setProcessPayment(true)
    if (token !== undefined) {
      // On demande à l'api un client secret pour l'intent payment
      ChallengesService.pay(props.challengeId, {
        amount: parseFloat(amountRef.current.defaultValue),
        cardToken: token
      })
        .then(response => {
          // Si l'api nous renvoie true et le secret
          if (response.data.success) {
            setPaymentStatus({
              status: 'submitted',
              error: false,
              paymentId: response.data.payment_id,
              message:
                'Merci nous avons bien reçu votre paiement. Cliquez sur "Jouer" pour tenter de remporter l\'expérience'
            })
          } else {
            setPaymentStatus({
              status: 'submitted',
              error: true,
              message: response.data.error
            })
          }
        })
        .catch(() => {
          // setProcessPayment(false)
        })
    } else {
      setProcessPayment(false)
    }
  }

  return (
    <div
      className={cx({
        'checkout-view': true,
        invalid: !canProceed
      })}
      style={{
        padding: 20
      }}
    >
      {challenge && challenge.status !== 'online' ? (
        <Redirect
          to={`/challenge/${slugify(challenge.title, {
            replacement: '_',
            remove: /[*+~.()'"!:@]/g,
            lower: true
          })}/${challenge.id}`}
        />
      ) : paymentOk ? (
        <Redirect to={`/play/${props.challenge.id}`} />
      ) : challenge ? (
        <>
          {processPayment && (
            <PopupProcessPayement
              play={() => setPaymentOk(true)}
              retry={() => {
                setProcessPayment(false)
                setPaymentStatus(null)
              }}
              paymentStatus={paymentStatus}
              challenge={challenge}
            />
          )}
          <p>
            Choisissez le montant que vous souhaitez donner pour débloquer le
            challenge <strong>”{challenge.title}”</strong>
          </p>
          <input
            type='number'
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            ref={amountRef}
          />
          {error && <span>{error}</span>}
          <em>Min : {challenge.price}€</em>
          <Paymill
            amount={inputValue}
            onTokenCreated={token => tryPay(token)}
            onErrorPay={error => onErrorPay(error)}
            errorPay={errorPay}
          />
          {/* <MollieCardElement
            canProceed={canProceed}
            onTokenCreated={token => tryPay(token)}
            onErrorPay={error => onErrorPay(error)}
            errorPay={errorPay}
          /> */}
        </>
      ) : (
        <span
          style={{
            display: 'block',
            textAlign: 'center',
            color: '#000',
            fontSize: '1rem',
            top: 'inherit',
            margin: 0
          }}
        >
          Chargement...
        </span>
      )}
    </div>
  )
}

export default connect(
  state => ({
    challenge: challengeDataSelector(state),
    participate: participateDataSelector(state),
    me: AppUserProfileSelector(state)
  }),
  { fetchUser }
)(Checkout)
