import shuffle from 'shuffle-array'

class MemoryCards {
  constructor (images) {
    this.cards = []
    this.imagesGames = images
  }

  generateCardSet () {
    //
    // Generate a set of cards with image pairs
    //
    this.cards = []
    let id = 1
    for (let i = 0; i <= this.imagesGames.length - 1; i++) {
      let card1 = {
        id: id,
        image: this.imagesGames[i].image,
        imageUp: false,
        matched: false
      }
      id++
      let card2 = {
        id: id,
        image: this.imagesGames[i].image,
        imageUp: false,
        matched: false
      }
      this.cards.push(card1)
      this.cards.push(card2)
      id++
    }

    // Randomize the card set
    shuffle(this.cards)
  }

  getCard (id) {
    for (let i = 0; i < 2 * this.imagesGames.length; i++) {
      if (this.cards[i].id === id) {
        return this.cards[i]
      }
    }
  }

  findPairs (id) {
    return this.cards.filter(card => card.image === id)
  }

  flipCard (id, imageUp) {
    this.getCard(id).imageUp = imageUp
  }

  setCardAsMatched (id, matched) {
    this.getCard(id).matched = matched
  }

  cardsHaveIdenticalImages (id1, id2) {
    return this.getCard(id1).image === this.getCard(id2).image
  }
}

export default MemoryCards
