import { createSelector } from 'reselect'

export const challengeSelector = state => state.challenge

export const challengeIsLoadingSelector = createSelector(
  [challengeSelector],
  challenge => challenge.isLoading
)

export const challengeDataSelector = createSelector(
  [challengeSelector],
  challenge =>
    challengeSelector && challenge.data ? challenge.data.challenge : null
)

export const challengeErrorSelector = createSelector(
  [challengeSelector],
  challenge => (challengeSelector && challenge.error ? challenge.error : null)
)

export const participateDataSelector = createSelector(
  [challengeSelector],
  challenge =>
    challenge.data && challenge.data.participate
      ? challenge.data.participate
      : []
)

export const gamesImagesSelector = createSelector(
  [challengeSelector],
  challenge =>
    challenge.data && challenge.data.gamesPictures
      ? challenge.data.gamesPictures
      : []
)
