import * as actions from './home.actions';
import initialState from "./initialState";

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.REQUEST_HOME: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actions.FETCH_HOME_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                data: action.home
            }
        }
        case actions.FETCH_HOME_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }
        }
        default: {
            return state
        }
    }
}