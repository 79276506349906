import React, { useEffect, useState, useContext } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { AppGetRedirectGame } from '../../../stores/selectors'
import { setRedirectGame } from '../../../stores/actions'
import { AuthContext } from '../../../context/AuthContext'
import ChallengesService from '../../../api/services/ChallengesService'
import Game from '../../../components/Game/Game'
import * as scroll from 'react-scroll'
import {
  challengeIsLoadingSelector,
  gamesImagesSelector
} from '../store/challenge.selectors'
import PageLoaderIndicator from '../../../components/shared/PageLoaderIndicator'
import slugify from 'slugify'
import { toast } from 'react-toastify'

function Play (props) {
  const context = useContext(AuthContext)
  const [challenge, setChallenge] = useState(null)
  const [canPlay, setCanPlay] = useState(true)
  const [startData, setStartData] = useState()
  // const [gameEnd, setGameEnd] = useState(false)

  useEffect(() => {
    scroll.animateScroll.scrollToTop()

    const { challengeId } = props.match.params

    ChallengesService.getDetails(challengeId).then(response =>
      setChallenge(response.data)
    )

    return () => {}
  }, [])

  const startGame = () => {
    const { challengeId } = props.match.params
    ChallengesService.participate(challengeId, 'start').then(response => {
      if (!response.data.success) {
        toast.error(
          'Désolée les participations à cette expérince sont clôturées',
          {
            position: toast.POSITION.TOP_CENTER,
            onClose: () => setCanPlay(false)
          }
        )
      }
      setStartData(response.data)
    })
  }

  const gameEnded = click => {
    const { challengeId } = props.match.params
    // setGameEnd( true )
    ChallengesService.participate(challengeId, 'end', {
      count: click
    }).then(() => {
      ChallengesService.getDetails(challengeId).then(response =>
        setChallenge(response.data)
      )
    })
  }

  return !context.isAuth ? (
    <Redirect to='/auth/login' />
  ) : !props.loadChallenge && challenge ? (
    challenge.challenge.status !== 'online' || !canPlay ? (
      <Redirect
        to={`/challenge/${slugify(challenge.challenge.title, {
          replacement: '_',
          remove: /[*+~.()'"!:@]/g,
          lower: true
        })}/${challenge.challenge.id}`}
      />
    ) : (
      <div
        className='game-content'
        style={{
          padding: '50px 20px'
        }}
      >
        <Game
          imagesGames={challenge.challenge.medias_memory}
          challengeEnd={challenge.challenge.date_end}
          id={challenge.challenge.id}
          challenge={challenge.participate ? challenge.participate : null}
          onStart={() => startGame()}
          onEnd={click => gameEnded(click)}
          canPlay={canPlay}
          startGameData={startData}
        />
      </div>
    )
  ) : (
    <PageLoaderIndicator />
  )
}

export default withRouter(
  connect(
    state => ({
      redirectGame: AppGetRedirectGame(state),
      loadChallenge: challengeIsLoadingSelector(state),
      gameImages: gamesImagesSelector(state)
    }),
    {
      setRedirectGame
    }
  )(Play)
)
