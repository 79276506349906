import React from 'react'
import FooterShapeTop from '../../assets/images/container.svg'
import LogoFooter from '../../assets/images/logo-formedable.svg'
import prismaLogo from '../../assets/images/prisma-logo.svg'
import logoFb from '../../assets/images/icon_fb.svg'
import logoInsta from '../../assets/images/icon_insta.svg'
import { useMediaQuery } from 'react-responsive'
import FooterDeskShape from '../../assets/images/footer-up.svg'
import IconReassure1 from '../../assets/images/icon_reassurance_1.svg'
import IconReassure2 from '../../assets/images/icon_reassurance_2.svg'
import IconReassure3 from '../../assets/images/icon_reassurance_3.svg'
import { Link } from 'react-router-dom'

function Footer () {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })

  return (
    <footer className='footer'>
      <div id='wave-container' className='css-0 eepbx3x0'>
        <img src={!isDesktop ? FooterShapeTop : FooterDeskShape} alt='bg' />
      </div>

      <div className='content'>
        <img src={LogoFooter} alt='logo' className='center' />

        <div className='flex'>
          <span>
            <h6>Suivez-nous !</h6>
            <div className='social-wrapper'>
              <a
                href='https://www.facebook.com/formedableofficiel/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={logoFb} alt='facebook' />
              </a>
              <a
                href='https://www.instagram.com/formedableofficiel/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={logoInsta} alt='intagram' />
              </a>
            </div>
          </span>

          <nav className='footer'>
            <ul>
              <li>
                <Link to='/page/about'>À PROPOS DE FORMEDABLE</Link>
              </li>
              <li>
                <Link to='/page/cgu'>CONDITIONS GÉNÉRALES</Link>
              </li>
              <li>
                <Link to='/page/politique-de-confidentialite'>
                  POLITIQUE DE CONFIDENTIALITÉ
                </Link>
              </li>
              <li>
                <Link to='/contact'>CONTACT</Link>
              </li>
            </ul>
          </nav>

          <nav className='footer'>
            <ul>
              <li>
                <img src={IconReassure1} alt='reassurance 1' />
                <a href='/#' className='light'>
                  Associations sélectionnées
                </a>
              </li>
              <li>
                <img src={IconReassure2} alt='reassurance 2' />
                <a href='/#' className='light'>
                  Data Sécurisées
                </a>
              </li>
              <li>
                <img src={IconReassure3} alt='reassurance 3' />
                <a href='/#' className='light'>
                  Paiements sécurisés
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <a
          href='https://www.prismamedia.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            style={{ width: 190 }}
            src={prismaLogo}
            alt='prismalogo'
            className='center'
          />
        </a>
      </div>
    </footer>
  )
}

export default Footer
