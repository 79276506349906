import base from './base'
import UserService from '../api/services/UserService'

export default class CustomAuth extends base {
  /**
   * Call API permettant de se logguer
   */
  login = ({ actions, values, ...rest }, AuthContextClass) => {
    UserService.login(values)
      .then(response => {
        actions.setSubmitting(false)
        AuthContextClass.authenticate(response)
      })
      .catch(error => {
        actions.setSubmitting(false)
        AuthContextClass.setError(error)
      })
  }

  /**
   * Call API permettant de s'inscrire
   */
  register = ({ actions, valuesSerialized }, AuthContextClass) => {
    UserService.register(valuesSerialized)
      .then(response => {
        if (response.data && response.data.code === 400) {
          actions.setSubmitting(false)
          AuthContextClass.setSuccess(null)
          AuthContextClass.setError(response.data.errors)
        } else {
          AuthContextClass.setSuccess(null)
          AuthContextClass.setError(null)
          UserService.login({
            username: valuesSerialized.email,
            password: valuesSerialized.plainPassword
          })
            .then(response => {
              actions.setSubmitting(false)
              AuthContextClass.authenticate(response)
            })
            .catch(() => {
              actions.setSubmitting(false)
              actions.setSubmitting(false)
            })
        }
      })
      .catch(error => {
        AuthContextClass.setSuccess(null)
        AuthContextClass.setError(error)
        actions.setSubmitting(false)
      })
  }

  /**
   * Call API permettant de re-intialiser son mot de passe
   */
  forgotPassword = ({ actions, values, ...rest }, AuthContextClass) => {
    UserService.resetPassword(values)
      .then(response => {
        // console.log(response)
        actions.setSubmitting(false)

        AuthContextClass.setError(null)
        AuthContextClass.setSuccess(null)

        if (response.success) {
          AuthContextClass.setSuccess(response)
        } else {
          AuthContextClass.setError(response)
        }
      })
      .catch(error => {
        AuthContextClass.setSuccess(null)
        AuthContextClass.setError(error)
        actions.setSubmitting(false)
      })
  }
}
