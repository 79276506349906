import React from 'react'
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return(
      <AuthContext.Consumer>
        { AuthContextValues  => {
          return(
            <Route
              {...rest}
              render={ props =>
                AuthContextValues.isAuth ? (
                  <Component {...props} { ...rest }/>
                ) : (
                    <Redirect
                      to={{
                        pathname: rest.routeProps && rest.routeProps.auth_redirect_path ? rest.routeProps.auth_redirect_path : '/auth/login' ,
                        state: { from: props.location }
                      }}
                    />
                  )
              }
            />
          )
        }}
      </AuthContext.Consumer>
    )
};