import React, { useEffect } from 'react'
import { Switch, Route, BrowserRouter} from "react-router-dom";
import { routes } from './routes';
import { PrivateRoute } from './PrivateRoute';
import { NotFound } from '../components/not-found/NotFound';
import ScrollToTop from './ScrollToTop';
import { Header } from '../components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import {  AppUserSelector } from '../stores/selectors';
import Footer from '../components/footer/Footer';

const AppRouter = (props) => {

    useEffect(() => {
      return () => {
      };
    }, [])

    return (
      <ScrollToTop>
        <Header />
            <Switch>
                {
                    // Les routes sont à définir dans le fichier src/routing/route.jsx
                routes.map( (r) => {
                    return (
                    r.private
                    ? (
                                <PrivateRoute
                                    key={r.path}
                                    component={r.component}
                                    path={r.path}
                                    exact
                                    user={props.user}
                                    {...r}
                                />
                    )
                    : (

                                    <Route
                                    key={r.path}
                                    render={ (props) =>
                                        <main className={"content " + r.label}>
                                        <
                                        r.component { ...props } {...r}
                                    />
                                        </main>
                                    }
                                    path={r.path}
                                    exact={r.exact}
                                    />
                      )
                  )
                })

              }
                {/* Si aucune route trouvé on affiche le composant NotFound */}
                <main className={"content"}>
                    <Route component={ NotFound }/>
                </main>
            </Switch>
        <Footer />
      </ScrollToTop>
    )
}

export default withRouter(connect(
  state => ({
    user: AppUserSelector(state)
  })
)(AppRouter));



