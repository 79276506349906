import React, { Component } from 'react'
import './Game.css'
import back from './images/back.svg'

class CardView extends Component {
  constructor (props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick () {
    if (!this.props.matched && !this.props.imageUp) {
      this.props.onClick(this.props.id, this.props.image)
    }
  }

  render () {
    let imPath = './images/'
    if (this.props.imageUp) {
      imPath = this.props.image
    } else {
      imPath = back
    }

    let className = 'Card'
    if (this.props.matched) {
      className = className + ' Matched'
    }
    if (this.props.imageUp && this.props.presentGame) {
      className = className + ' flipped'
    }

    return (
      <img className={className} src={imPath} alt='' onClick={this.onClick} />
    )
  }
}

export default CardView
