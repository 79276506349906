import React from 'react'
// import CoverShape from '../../assets/images/cover-shape.svg'

function CoverPage (props) {
  return !props.isDesktop ? (
    <div className='cover'>
      {props.tag && <div className='tag'>{props.tag}</div>}
    </div>
  ) : (
    <div className='cover-wrapper'>
      <div className='coverdesk'>
        {props.tag && <div className='tag'>{props.tag}</div>}
        {!props.isChallenge && props.image && (
          <img
            className='cover-img'
            src={props.image.image}
            alt={props.title}
            title={props.title}
          />
        )}
        <svg
          className='coverdesk__small-mask'
          preserveAspectRatio='xMidYMax meet'
          viewBox='0 0 470 110'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0,0l0,110l470,0l0,-36.524c-18.014,24.204 -76.726,27.335 -119.67,20.249c-19.059,-3.181 -34.997,-8.384 -50.318,-13.386c-21.234,-6.932 -41.282,-13.476 -66.807,-13.715c-13.68,-0.182 -28.943,1.44 -45.074,3.154c-35.638,3.786 -75.511,8.023 -111.911,-5.754c-29.703,-11.242 -57.059,-34.501 -76.22,-64.024Z'
            fill='#fff'
          />
        </svg>
        <svg
          className='coverdesk__large-mask'
          viewBox='0 0 1920 570'
          preserveAspectRatio='xMinYMax meet'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1920,342.699c-11.42,19.04 -24.23,36.977 -38.42,53.565c-103.2,120.002 -278.79,169.741 -413.48,132.539c-92.73,-25.611 -166.02,-92.285 -231.53,-151.877c-29.65,-26.973 -57.71,-52.494 -85.25,-72.1c-51.23,-36.786 -100.78,-52.976 -153.253,-70.124c-37.862,-12.373 -77.252,-25.245 -119.903,-46.711c-79.125,-40.009 -157.892,-118.827 -182.296,-187.991l-695.868,0l0,570l1920,0l0,-227.301Z'
            fill='#fff'
          />
        </svg>
      </div>
    </div>
  )
}

export default CoverPage
