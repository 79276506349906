import React from 'react'
import { NavLink } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { connect } from 'react-redux'
import { AppMenuSelector } from '../../stores/app/app.selectors'
import cx from 'classnames/bind'
import ArrowDown from '../../assets/images/icon_drop.svg'

function MenuMobile (props) {
  const context = React.useContext(AuthContext)
  const [indexSubmenuOpen, setIndexSubmenuOpen] = React.useState(null)

  const toggleSubmenu = index => {
    indexSubmenuOpen === index
      ? setIndexSubmenuOpen(null)
      : setIndexSubmenuOpen(index)
  }

  const delayEdge = 50

  return (
    <>
      <div id='appShadow' />
      <nav id='menu-mobile-wrapper' className='animate-inFromLeft '>
        <ul>
          {props.menuItems.map((item, index) => (
            <li
              key={index}
              className={cx({
                menu__item: true,
                hasChhild: item.childs,
                isOpen: indexSubmenuOpen === index
              })}
              style={{ animationDelay: `${(index + 1) * delayEdge}ms` }}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                {item.href ? (
                  <NavLink excat activeClassName='active' to={item.href}>
                    {item.label}
                  </NavLink>
                ) : (
                  <span
                    style={{ width: '100%' }}
                    onClick={() => toggleSubmenu(index)}
                  >
                    {item.label}
                  </span>
                )}
                {item.childs && (
                  <span>
                    <img
                      style={{ padding: '0 25px' }}
                      onClick={() => toggleSubmenu(index)}
                      src={ArrowDown}
                      alt='down'
                    />
                  </span>
                )}
              </span>

              {item.childs && (
                <div
                  className={cx({
                    submenu: true,
                    open: indexSubmenuOpen === index
                  })}
                >
                  <ul>
                    {item.childs.map((child, index2) => (
                      <li
                        key={index2}
                        className='menu__item'
                        style={{
                          animationDelay: `${(index2 + 1) * delayEdge}ms`
                        }}
                      >
                        <NavLink excat activeClassName='active' to={child.href}>
                          {child.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
          <li
            className={cx({
              menu__item: true,
              login: true
            })}
          >
            <NavLink
              exact
              activeClassName='active'
              to={context.isAuth ? '/espace-personnel' : '/auth/login'}
            >
              {context.isAuth ? 'Espace personnel' : 'Se connecter'}
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default connect(
  state => ({
    menuItems: AppMenuSelector(state)
  }),
  null
)(MenuMobile)
