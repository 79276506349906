import apiService from '../../conf/api.custom'
import { API_ENDPOINT } from '../constants'

export default {
  login: values => {
    return apiService
      .post(`${API_ENDPOINT.USER}/login`, values)
      .then(response => response.data)
      .then(user => user)
  },
  register: values => {
    return apiService
      .post(`${API_ENDPOINT.USER}/register`, values)
      .then(response => response.data)
      .then(user => user)
  },
  resetPassword: values => {
    return apiService
      .post(`${API_ENDPOINT.USER}/resetpassword`, values)
      .then(response => response.data)
      .then(user => user)
  },
  confirmResetPassword: values => {
    return apiService
      .post(`${API_ENDPOINT.USER}/reset_password_confirm`, values)
      .then(response => response.data)
      .then(user => user)
  },
  validateToken: () => {
    return apiService
      .get(`${API_ENDPOINT.USER}/validate`)
      .then(response => response.data)
      .then(user => user)
  },
  updateProfile: valuesSerialized => {
    return apiService
      .put(`${API_ENDPOINT.USER}/me`, valuesSerialized)
      .then(response => response.data)
      .then(user => user)
  },
  getMe: () => {
    return apiService
      .get(`${API_ENDPOINT.USER}/me`)
      .then(response => response.data)
      .then(user => user)
  },
  getMeChallenges: () => {
    return apiService
      .get(`${API_ENDPOINT.USER}/me/challenges`)
      .then(response => response.data)
      .then(user => user)
  }
}
