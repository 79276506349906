import React, { useEffect, useState } from 'react'
import SectionTitle from './SectionTitle'
import ChallengeItem from './ChallengeItem'
import ChallengesService from '../../api/services/ChallengesService'

function LatestExperience (props) {
  const [latestExperience, setLatestExperience] = useState(null)

  useEffect(
    () => {
      ChallengesService.featured(props.challenge_id ? props.challenge_id : '')
        .then(response => setLatestExperience(response.data))
        .catch()
      return () => {}
    },
    [props]
  )

  return (
    latestExperience && (
      <>
        <SectionTitle title='Nos EXPÉRIENCES' subtitle='DU MOMENT' />
        <div
          className='latest-challenge'
          style={{
            paddingBottom: 50,
            maxWidth: '85%',
            margin: '0 auto'
          }}
        >
          {latestExperience.map(data => (
            <ChallengeItem key={data.id} data={data} time={data.date_end} now />
          ))}
        </div>
      </>
    )
  )
}

export default LatestExperience
