import React, { useContext, useState, useEffect } from 'react'
import './assets/style.scss'
import AppRouter from './routing/AppRouter'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ModalContext } from './context/ModalContext'
import Modal from './components/shared/Modal'
import cx from 'classnames/bind'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { connect } from 'react-redux'
import { fetchMenu } from './stores/actions'
import { useMediaQuery } from 'react-responsive'
import { challengeDataSelector } from './features/challenge/store/challenge.selectors'
import moment from 'moment'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-PTWMPBB'
  // dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)

function App (props) {
  const disableReactDevTools = () => {
    const noop = () => undefined
    const DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__

    if (
      typeof DEV_TOOLS === 'object' &&
      process.env.NODE_ENV === 'production'
    ) {
      for (const [key, value] of Object.entries(DEV_TOOLS)) {
        DEV_TOOLS[key] = typeof value === 'function' ? noop : null
      }
    }
  }

  const isDesktop = useMediaQuery({
    query: '(min-width: 1025px)'
  })

  const [dataModal] = useContext(ModalContext)
  const [fixedButton, setfixedButton] = useState(false)
  const [appDataLoading, setAppDataLoading] = React.useState(true)

  useEffect(() => {
    disableReactDevTools()
    axios.all([props.fetchMenu()]).then(() => setAppDataLoading(false))

    return () => {}
  }, [])

  useEffect(() => {
    props.challenge && isBegin(props.challenge.date_start)
      ? setfixedButton(true)
      : setfixedButton(false)

    return () => {}
  }, [props.challenge])

  // TODO: track visitors w/ GTM
  // const trackPage = page => {
  //   console.log(page)
  // }

  useEffect(() => {
    // if (process.env.NODE_ENV === 'production') {
    //trackPage(props.location.pathname)
    // }

    return () => {}
  }, [props.location])

  const isBegin = startDate => {
    return !moment.unix(startDate, 'MM/DD/YYYY').isAfter(moment())
  }

  return !appDataLoading ? (
    <>
      <div
        id='page'
        className={cx({
          fixedButton: fixedButton && !isDesktop
        })}
      >
        <Helmet titleTemplate='%s - Formedable' defaultTitle='Formedable'>
          <meta name='description' content='Formedable' />
          <meta property='fb:app_id' content='682269705633053' />
          <meta name='twitter:card' content='summary' />
        </Helmet>
        <ToastContainer position='top-center' autoClose={3000} />
        <AppRouter />
        {dataModal.display && <Modal data={dataModal} />}
      </div>
    </>
  ) : null
}

export default withRouter(
  connect(
    state => ({
      challenge: challengeDataSelector(state)
    }),
    { fetchMenu }
  )(App)
)
