import React, { Component } from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames/bind'
import { Link, withRouter } from 'react-router-dom'
import { AUTH_METHOD } from '../../../auth/base'
import { AppGetRedirectGame } from '../../../stores/selectors'
import { connect } from 'react-redux'
import LocalStorageInstance from '../../../helpers/LocalStorage'
import { AuthContext } from '../../../context/AuthContext'

const ComposantErreur = props => {
  return (
    <div className='error-message'>
      <span>{props.children}</span>
    </div>
  )
}

const ComposantInput = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <div className={cx('form-group', { invalid: errors[field.name] })}>
      <label> {props.label}* </label>
      <input
        type='text'
        {...props}
        className='form-control'
        {...field}
        autoComplete='off'
      />
    </div>
  )
}

// export const AuthContext = React.createContext(AuthContext);

class Login extends Component {
  static contextType = AuthContext

  constructor (props) {
    super(props)
    this.state = {
      isSubmitting: false,
      error: null
    }
    this.targetRef = React.createRef()

    // console.log( this.context.isAuth )
  }

  submit = (values, actions) => {
    this.props.authContext.call(AUTH_METHOD.LOGIN, { values, actions })
  }

  userSchema = Yup.object().shape({
    username: Yup.string().required('Champs obligatoire'),
    password: Yup.string().required('Champs obligatoire')
  })

  componentWillUnmount () {
    LocalStorageInstance.remove('gameId')
  }

  componentDidMount () {
    this.context.setError(null)
  }

  render () {
    return (
      <div className='login-wrapper'>
        <div className='wrapper-content center'>
          <h2>Se connecter</h2>
          <Formik
            onSubmit={this.submit}
            initialValues={{ username: '', password: '' }}
            validationSchema={this.userSchema}
            validateOnChange
          >
            {({ handleSubmit, isSubmitting, actions }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name='username'
                  label='Email'
                  component={ComposantInput}
                />
                <ErrorMessage name='username' component={ComposantErreur} />
                <Field
                  name='password'
                  type='password'
                  label='Mot de passe'
                  component={ComposantInput}
                />
                <ErrorMessage name='password' component={ComposantErreur} />
                <button
                  type='submit'
                  className='btn-participate'
                  disabled={isSubmitting || this.state.isSubmitting}
                >
                  Je valide
                </button>
                {this.context.error && this.context.error.data && (
                  <p
                    style={{
                      top: 'inherit',
                      textAlign: 'center',
                      marginTop: 20
                    }}
                    className='error-message'
                  >
                    {' '}
                    {this.context.error.data.message}
                  </p>
                )}
                <span className='form_link text-center'>
                  <Link className='link' to='/auth/forgot-password'>
                    Mot de passe oublié?
                  </Link>
                  <em>
                    Vous n'avez pas encore de compte ?
                    <Link
                      className='btn-participate'
                      style={{
                        marginTop: 20
                      }}
                      to={
                        LocalStorageInstance.get('gameId')
                          ? `/payment/${LocalStorageInstance.get(
                            'gameId'
                          )}/?step=register`
                          : '/auth/register'
                      }
                    >
                      Inscription
                    </Link>
                  </em>
                </span>
              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(state => ({ redirectGame: AppGetRedirectGame(state) }))(Login)
)
