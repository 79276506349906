import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import PriceTime from '../../components/shared/PriceTime'
import SectionTitle from '../../components/shared/SectionTitle'
import GallerySlider from '../../components/shared/GallerySlider'
import PartnerBlock from '../../components/shared/PartnerBlock'
import BlockHowItWork from '../../components/shared/BlockHowItWork'
import CoverPage from '../../components/shared/CoverPage'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  challengeDataSelector,
  challengeIsLoadingSelector,
  participateDataSelector,
  challengeErrorSelector
} from './store/challenge.selectors'
import { fetchChallenge, resetChallenge } from './store/challenge.actions'
import PageLoaderIndicator from '../../components/shared/PageLoaderIndicator'
import CoverShape from '../../assets/images/cover-shape.svg'
import Share from './components/Share'
import moment from 'moment'
import LimitedContent from './components/LimitedContent'
import { useMediaQuery } from 'react-responsive'
import { setRedirectGame } from '../../stores/actions'
import cx from 'classnames/bind'
import { ModalContext } from '../../context/ModalContext'
import Results from './components/Results'
import './store/index'
import LatestExperience from '../../components/shared/LatestExperience'
import slugify from 'slugify'
import { Redirect } from 'react-router-dom'
function index (props) {
  const authContext = useContext(AuthContext)
  const [dataModal, setDataModal] = useContext(ModalContext)

  useEffect(() => {
    const { id } = props.match.params

    if (id) {
      props.fetchChallenge(id)
    }

    window.addEventListener('scroll', listener)

    return () => {
      window.removeEventListener('scroll', listener)
      props.resetChallenge()
      setDataModal({
        ...dataModal,
        display: false,
        data: { component: null }
      })
    }
  }, [props.location])

  useEffect(() => {
    const { id } = props.match.params
    props.setRedirectGame(`/play/${id}`)
    // LocalStorageInstance.store('gameId', id)

    return () => {}
  }, [authContext])

  useEffect(() => {
    if (props.data && isPassed(props.data.date_end)) {
      setDataModal({
        ...dataModal,
        display: true,
        data: { component: Results, challenge: props.participate }
      })
    }

    return () => {}
  }, [props.data])

  function getBodyScrollTop () {
    const el = document.scrollingElement || document.documentElement
    return el.scrollTop
  }

  const listener = () => {
    const deceleration = 0.5
    const transformY = getBodyScrollTop() * deceleration
    // const normalTransfrom = document.documentElement.scrollTop;

    // requestAnimationFrame(() => {
    if (document.querySelector('.cover')) {
      document.querySelector(
        '.cover'
      ).style.transform = `translate3d(0,-${transformY}px,0)`
    }

    if (document.querySelector('.cover-wrapper')) {
      document.querySelector(
        '.cover-wrapper'
      ).style.transform = `translate3d(0,-${transformY}px,0)`
    }
    // document.querySelector('.effect-cover').style.transform = `translate3d(0,-${normalTransfrom}px,0)`
    // });
  }

  const isBegin = startDate => {
    return !moment.unix(startDate, 'MM/DD/YYYY').isAfter(moment())
  }

  const isPassed = endDate => {
    return moment.unix(endDate, 'MM/DD/YYYY').isBefore(moment())
  }

  const isDesktop = useMediaQuery({
    query: '(min-width: 960px)'
  })

  const getAppropriateLink = id => {
    return props.participate.nbrTry && props.participate.nbrTry > 0
      ? `/play/${id}`
      : `/payment/${id}`
  }

  return props.loading ? (
    <PageLoaderIndicator />
  ) : props.error ? (
    <Redirect to='/not-found' />
  ) : (
    props.data && (
      <div
        className={cx({
          isFinish: isPassed(props.data.date_end)
        })}
      >
        <Helmet
          title={props.data.title}
          meta={[
            {
              name: 'description',
              content: props.data.metadescription && props.data.metadescription
            },
            { property: 'og:title', content: props.data.title },
            {
              property: 'og:site_name',
              content: 'formedable'
            },
            { property: 'og:type', content: 'website' },
            {
              property: 'og:url',
              content: `https://${window.location.hostname}/challenge/${slugify(
                props.data.title,
                {
                  replacement: '_',
                  remove: /[*+~.()'"!:@]/g,
                  lower: true
                }
              )}/${props.data.id}`
            },
            {
              property: 'og:description',
              content: props.data.metadescription && props.data.metadescription
            },
            {
              property: 'og:image',
              content:
                props.data.primary_media && props.data.primary_media.image
            },
            {
              property: 'og:image:width',
              content:
                props.data.primary_media.image_width &&
                props.data.primary_media.image_width
            },
            {
              property: 'og:image:height',
              content:
                props.data.primary_media.image_height &&
                props.data.primary_media.image_height
            },
            { name: 'twitter:title', content: props.data.title },
            {
              name: 'twitter:image',
              content:
                props.data.primary_media && props.data.primary_media.image
            }
          ]}
        />

        <CoverPage
          tag='EXPÉRIENCE'
          isDesktop={isDesktop}
          isChallenge={true}
          image={props.data && props.data.primary_media}
          title={props.data && props.data.title}
        />

        <div className='effect-cover-container'>
          {!isDesktop && (
            <span className='effect-cover'>
              <img src={CoverShape} alt='shape' />
            </span>
          )}

          <article className='challenge-detail'>
              {props.data.status === 'online' && isDesktop && (
                  <Link to={getAppropriateLink(props.data.id)} className='btn-play'>
                      JE VEUX PARTICIPER
                  </Link>
              )}
            <div
              style={{ margin: !isDesktop ? '0 auto' : '120px auto' }}
              className={cx({
                'wrapper-content': true,
                center: true,
                isDesk: isDesktop
              })}
            >
              <PriceTime
                time={
                  !isBegin(props.data.date_start)
                    ? props.data.date_start
                    : props.data.date_end
                }
                startPrice={props.data.price}
                now={isBegin(props.data.date_start)}
                isPassed={isPassed(props.data.date_end)}
              />

              <h1>{props.data.title}</h1>

              <LimitedContent content={props.data.description} />

              {props.data.celebrity && (
                <div className='celebrity'>
                  <img
                    src={
                      props.data.celebrity.photo
                        ? props.data.celebrity.photo.image
                        : ''
                    }
                    alt={props.data.celebrity.name}
                    title={props.data.celebrity.name}
                  />
                  <div className='text-celeb'>
                    <span>Encouragé par</span>
                    <h5>{props.data.celebrity.name}</h5>
                  </div>
                </div>
              )}
            </div>

            {props.data.medias.length > 0 && (
              <GallerySlider medias={props.data.medias} />
            )}

            <div className='wrapper-content center'>
              {props.data.status === 'online' && isDesktop && (
                  <Link to={getAppropriateLink(props.data.id)} className='btn-play'>
                      JE VEUX PARTICIPER
                  </Link>
              )}
            </div>

            <div className='wrapper-content center'>
              {props.data.support_text && <h2>{props.data.support_text}</h2>}
              <div
                className='partner-block-wrapper'
                style={{
                  paddingBottom: 110
                }}
              >
                {props.data.association && (
                  <PartnerBlock
                    data={props.data.association}
                    type='association'
                  />
                )}
                {props.data.partner && (
                  <PartnerBlock data={props.data.partner} type='parrain' />
                )}
              </div>
            </div>

            <BlockHowItWork
              style={{
                position: 'relative',
                marginTop: -100
              }}
            />

            <LatestExperience challenge_id={props.data.id} />

            {props.data.status === 'online' && (
              <Link
                to={getAppropriateLink(props.data.id)}
                className='btn-play fixed'
              >
                JE VEUX PARTICIPER
              </Link>
            )}
          </article>
        </div>
      </div>
    )
  )
}

export default withRouter(
  connect(
    state => ({
      data: challengeDataSelector(state),
      participate: participateDataSelector(state),
      loading: challengeIsLoadingSelector(state),
      error: challengeErrorSelector(state)
    }),
    { fetchChallenge, resetChallenge, setRedirectGame }
  )(index)
)
