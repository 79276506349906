import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Auths from '../auth'
import * as _ from 'lodash'
import LocalStorageInstance from '../helpers/LocalStorage'

export const AuthContext = React.createContext()

class AuthProvider extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isAuth: LocalStorageInstance.get('authUser') || null,
      // isAuth: true ,
      authData: null,
      error: null,
      success: null
    }

    let AuthChoice = _.find(Auths, { type: this.props.provider })
    this.auth = _.isUndefined(AuthChoice) ? undefined : new AuthChoice.model()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ error: null, success: null })
    }
  }

  call = (method, params = {}) => {
    !_.isUndefined(this.auth) && this.auth[method](params, this)
  }

  setAuth = value => {
    this.setState({ isAuth: value })
  }

  setError = error => {
    this.setState({ error })
  }

  setSuccess = success => {
    this.setState({ success })
  }

  authenticate = data => {
    LocalStorageInstance.store('authUser', data.token)
    this.setState({ isAuth: true })
    this.props.fetchUser()
  }

  render () {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          ...this.state,
          call: this.call,
          setAuth: this.setAuth,
          setError: this.setError,
          setSuccess: this.setSuccess
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export default withRouter(AuthProvider)
