import React, { createContext, useState } from 'react';

export const GameContext = createContext();

export function GameProvider({ children }) {

  const [data, setData] = useState({
      gameId: null,
      minPrice : 0
  });


  return (
    <GameContext.Provider value={
        [data, setData]
    }>
      {children}
    </GameContext.Provider>
  );
}