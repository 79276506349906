export default class base  {

    logout = ( args , context) => {
        localStorage.removeItem('authUser')
        context.setState({ isAuth: false })
    }

}


export const AUTH_METHOD = {
    LOGIN: 'login',
    REGISTER: 'register',
    FORGOT_PASSWORD: 'forgotPassword',
    LOGOUT: 'logout'
}