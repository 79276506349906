import initialState from './initialState'
import * as actions from './challenge.actions'

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_CHALLENGE: {
      return {
        ...state,
        isLoading: true
      }
    }
    case actions.FETCH_CHALLENGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.todos
      }
    }
    case actions.FETCH_CHALLENGE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }
    default: {
      return state
    }
  }
}
