import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import store from './stores'
import { BrowserRouter as Router } from 'react-router-dom'
import AuthProvider from './context/AuthContext'
import { ModalProvider } from './context/ModalContext'
import { GameProvider } from './context/GameContext'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Router basename={process.env.REACT_APP_ROUTER_BASENAME}>
      <AuthProvider provider='custom'>
        <ModalProvider>
          <GameProvider>
            <App />
          </GameProvider>
        </ModalProvider>
      </AuthProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.register()
