import LocalStorageInstance from "../../helpers/LocalStorage";

export default {
    user : null,
    redirectGame: LocalStorageInstance.get('gameId') ? `/play/${LocalStorageInstance.get('gameId') }` : '/',
    menu: [
        {
            label: 'Les expériences',
            href: '/'
        },
        {
            label: 'Les associations',
            href: null,
            children : [
                {
                    label: "Association Petits Princes",
                    href: '/associations/association-petits-princes/10'
                },
                {
                    label: "Association Petits Princes",
                    href: '/associations/association-petits-princes/10'
                },
                {
                    label: "Association Petits Princes",
                    href: '/associations/association-petits-princes/10'
                },
                {
                    label: "Association Petits Princes",
                    href: '/associations/association-petits-princes/10'
                }
            ]
        },
        {
            label: 'Comment ça marche ?',
            href: '/page/comment-ca-marche',
            type : 'page',        },
        {
            label: 'Nos engagements',
            href: '/page/nos-engagements',
        },
        {
            label: 'Nous contacter',
            href: '/nous-contacter'
        }
    ],
    isLoading: false,
    error: null
}