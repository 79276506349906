import React, { useState, useEffect } from 'react'
import cx from 'classnames/bind'

function BurgerIcon (props) {
  const [open, setOpen] = useState(false)

  const openMenu = () => {
    setOpen(!open)
  }

  useEffect(
    () => {
      props.onPress(open)
      return () => {}
    },
    [open]
  )

  return (
    <div
      id='nav-icon'
      className={cx({
        open: open
      })}
      onClick={() => openMenu()}
    >
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
    // <div
    //   className={cx({
    //     'burger-icon': true,
    //     open: open
    //   })}
    //   onClick={openMenu}
    // >
    //   <span />
    //   <span />
    //   <span />
    // </div>
  )
}

export default BurgerIcon
