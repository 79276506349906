import React, { createContext, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const ModalContext = createContext();

export function ModalProvider({ children }) {

  const [data, setData] = useState({
      display: false,
      data: {
        component: null
      }
  });

  const targetElement = document.querySelector("#content_modal");

  React.useEffect(() => {

    if( data.display ) {
      disableBodyScroll(targetElement)
    }else{
      enableBodyScroll(targetElement)
    }

    return () => {
      
    };

  }, [data])

  return (
    <ModalContext.Provider value={
        [data, setData]
    }>
      {children}
    </ModalContext.Provider>
  );
}