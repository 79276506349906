import React from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames/bind'

const ComposantErreur = props => {
  return (
    <div className='error-message'>
      <span>{props.children}</span>
    </div>
  )
}

const ComposantInput = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <div
      className={cx('form-group', {
        invalid: touched[field.name] && errors[field.name]
      })}
    >
      <label> {props.label} </label>
      <input type='text' {...props} className='input-field' {...field} />
    </div>
  )
}

function Paymill (props) {
  const paymill = window.paymill

  const [error, setError] = React.useState(null)
  const [fetchingCB, setFetchingCB] = React.useState(false)

  const submitForm = (values, actions) => {
    actions.setSubmitting(false)
    setError(null)
    const expSplit = values.expiration.split('/')
    const first2yearsdigit = new Date()
      .getFullYear()
      .toString()
      .substring(0, 2)
    setFetchingCB(true)

    paymill.createToken(
      {
        number: `${values.cardnumber.split(' ').join('')}`,
        exp_month: `${expSplit[0]}`,
        exp_year: `${first2yearsdigit}${expSplit[1]}`,
        cvc: `${values.cvc}`,
        amount_int: parseInt(props.amount * 100),
        currency: 'EUR',
        cardholder: values.cardholder
      },
      (error, result) => {
        setFetchingCB(false)
        if (error) {
          // Shows the error above the form
          setError(error.apierror)
        } else {
          const token = result.token
          props.onTokenCreated(token)
        }
      }
    )
  }

  const paySchema = Yup.object().shape({
    cardholder: Yup.string().required('Champs obligatoire'),
    cardnumber: Yup.string()
      .required('Champs obligatoire')
      .test('card', 'Carte invalide.', value => {
        if (value !== undefined) {
          return paymill.validateCardNumber(value)
        }

        return false
      }),
    cvc: Yup.string()
      .required('Champs obligatoire')
      .max('3', '3 chifres max'),
    expiration: Yup.string()
      .required('Obligatoire')
      .test('exp', 'Format invalide (MM/YY) ou carte expirée.', value => {
        if (value !== undefined) {
          const expSplit = value.split('/')
          const first2yearsdigit = new Date()
            .getFullYear()
            .toString()
            .substring(0, 2)
          return paymill.validateExpiry(
            expSplit[0],
            `${first2yearsdigit}${expSplit[1]}`
          )
        }
        return false
      })
  })

  const renderError = message => {
    switch (message) {
      case 'unknown_error':
        return "Désolée, nous n'avons pas pu traiter le paiment"
      default:
        break
    }
  }

  return (
    <Formik
      onSubmit={submitForm}
      validateOnBlur
      validationSchema={paySchema}
      initialValues={{
        cardnumber: '',
        cardholder: '',
        expiration: '',
        cvc: ''
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          id='pay_form'
          onSubmit={handleSubmit}
          className='w-50 bg-white border p-5 d-flex flex-column'
        >
          <div className='card-element'>
            <Field
              name='cardholder'
              label='Titulaire de la carte *'
              component={ComposantInput}
            />
            <ErrorMessage name='cardholder' component={ComposantErreur} />
          </div>

          <div className='card-element'>
            <Field
              name='cardnumber'
              label='Numéro de carte bleue*'
              component={ComposantInput}
              render={({
                field,
                form,
                form: { touched, errors },
                ...props
              }) => (
                <div
                  className={cx('form-group', {
                    invalid: touched[field.name] && errors[field.name]
                  })}
                >
                  <label> Numéro de carte bleue* </label>
                  <input
                    placeholder='XXXX XXXX XXXX XXXX'
                    type='text'
                    {...props}
                    className='input-field'
                    {...field}
                    onChange={e => {
                      let value = e.target.value
                        .replace(/[^\dA-Z]/g, '')
                        .replace(/(.{4})/g, '$1 ')
                        .trim()
                      form.setFieldValue('cardnumber', value)
                    }}
                  />
                </div>
              )}
            />
            <ErrorMessage name='cardnumber' component={ComposantErreur} />
          </div>

          <div className='split-form flexCol'>
            <div className='card-element'>
              <Field
                name='expiration'
                label="Date d'expiration*"
                placeholer='MM/YYYY'
                render={({
                  field,
                  form,
                  form: { touched, errors },
                  ...props
                }) => (
                  <div
                    className={cx('form-group', {
                      invalid: touched[field.name] && errors[field.name]
                    })}
                  >
                    <label> Date d'expiration* </label>
                    <input
                      placeholder='MM/YY'
                      type='text'
                      {...props}
                      className='input-field'
                      {...field}
                      onChange={e => {
                        let value = e.target.value
                        if (value.length === 2) {
                          value = `${value}/`
                        }
                        form.setFieldValue('expiration', value)
                      }}
                    />
                  </div>
                )}
                placeholder='MM/YY'
              />
              <ErrorMessage name='expiration' component={ComposantErreur} />
            </div>

            <div className='card-element'>
              <Field
                name='cvc'
                placeholder='XXX'
                label='CVV*'
                component={ComposantInput}
              />

              <ErrorMessage name='cvc' component={ComposantErreur} />
            </div>
          </div>

          {error ? (
            <div className='error-message center'>{renderError(error)}</div>
          ) : null}

          <button
            type='submit'
            className='btn-participate'
            disabled={isSubmitting}
          >
            {!fetchingCB ? 'Envoyer' : 'Validation...'}
          </button>
        </form>
      )}
    </Formik>
  )
}

export default Paymill
