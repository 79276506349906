import axios from 'axios'
import LocalStorageInstance from '../helpers/LocalStorage'
// import createAuthRefreshInterceptor from 'axios-auth-refresh';
// import { AuthContext } from '../context/AuthContext';

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

/**
 * Ajout automatique du token sur l'ensemble des requête effectué avec AXIOS
 */
apiService.interceptors.request.use(
  config => {
    const token = LocalStorageInstance.get('authUser')

    if (token != null) {
      // UserService.validateToken().then( reponse => { if( response.data ))
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * Gestion des cas d'erreur renvoyé par l'API
 */
apiService.interceptors.response.use(
  (response, props) => {
    return response
  },
  (error, props) => {
    // On traite ici les différent type d'erreur reçu via l'api
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          LocalStorageInstance.remove('authUser')
          window.location.reload()
          break
        case 404:
          // this.props.history.push('/error')
          break
        case 500:
          // this.props.history.push('/error')
          break
        default:
          break
      }
    } else {
      // window.location.replace('/error')
      return
    }

    return Promise.reject(error.response)
  }
)

export default apiService
