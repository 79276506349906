import React, { Component } from 'react'
import './Game.css'
import CardView from './CardView'
import MemoryCards from './MemoryCards'
import { cx } from 'emotion'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fr' // without this line it didn't work
import { Redirect } from 'react-router-dom'
import * as scroll from 'react-scroll'

const Timer = ({ time = 0 }) => <p className='timer'>{formatTime(time)}</p>

const formatTime = time => {
  if (time < 0) return '--:--'
  const h = Math.floor(time / 3600)
  const m = Math.floor((time % 3600) / 60)
  const mm = m < 10 ? `0${m}` : m
  const s = time % 60
  const ss = s < 10 ? `0${s}` : s
  if (h > 0) return [h, mm, ss].join(':')
  return `${m}:${ss}`
}

class Game extends Component {
  constructor (props) {
    super(props)
    this.onCardClicked = this.onCardClicked.bind(this)
    this.onPlayAgain = this.onPlayAgain.bind(this)
    this.memoryCards = new MemoryCards(this.props.imagesGames)
    this.timeInterval = null
  }

  componentWillMount () {
    this.initGame()
  }

  initGame () {
    this.memoryCards.generateCardSet()
    this.setState({
      startPresentation: false,
      presentEnded: false,
      secondsElapsed: 0,
      turnNo: 0,
      pairsFound: 0,
      numClicksWithinTurn: 0,
      firstId: undefined,
      secondId: undefined,
      displayNewGameButton: false,
      cardFlipped: null
    })
  }

  tick () {
    this.setState({
      secondsElapsed: this.state.secondsElapsed + 1
    })
  }

  presentGame () {
    const timer = 1000

    for (let i = 0; i < 2 * this.memoryCards.imagesGames.length; i++) {
      setTimeout(() => {
        this.memoryCards.flipCard(this.memoryCards.cards[i].id, true)
        this.setState({
          cardFlipped: this.memoryCards.cards[i].id,
          indexFlipped: i
        })

        if (i === 2 * this.memoryCards.imagesGames.length - 1) {
          setTimeout(() => {
            this.memoryCards.flipCard(this.memoryCards.cards[i].id, false)
            this.setState({
              presentEnded: true
            })
          }, timer)
        }
      }, i * timer)
    }
  }

  getCardViews () {
    let cardViews = []
    let onClick = this.onCardClicked
    this.memoryCards.cards.forEach(c => {
      let cardView = (
        <CardView
          key={c.id}
          id={c.id}
          image={c.image}
          imageUp={c.imageUp}
          matched={c.matched}
          onClick={onClick}
          presentGame={!this.state.presentEnded}
        />
      )
      cardViews.push(cardView)
    })
    return cardViews
  }

  clearCards (id1, id2) {
    if (this.state.numClicksWithinTurn !== 2) {
      return
    }
    this.memoryCards.flipCard(this.state.firstId, false)
    this.memoryCards.flipCard(this.state.secondId, false)
    this.setState({
      firstId: undefined,
      secondId: undefined,
      numClicksWithinTurn: 0,
      turnNo: this.state.turnNo + 1
    })
  }

  onCardClicked (id, image) {
    if (!this.state.presentEnded) return

    if (this.timeInterval === null) {
      this.timeInterval = setInterval(this.tick.bind(this), 1000)
      this.props.onStart()
    }

    if (
      this.state.numClicksWithinTurn === 0 ||
      this.state.numClicksWithinTurn === 2
    ) {
      if (this.state.numClicksWithinTurn === 2) {
        clearTimeout(this.timeout)
        this.clearCards(this.state.firstId, this.state.secondId)
      }
      this.memoryCards.flipCard(id, true)
      this.setState({
        firstId: id,
        numClicksWithinTurn: 1
      })
    } else if (this.state.numClicksWithinTurn === 1) {
      this.memoryCards.flipCard(id, true)
      this.setState({
        secondId: id,
        numClicksWithinTurn: 2
      })

      if (this.memoryCards.cardsHaveIdenticalImages(id, this.state.firstId)) {
        this.memoryCards.setCardAsMatched(this.state.firstId, true)
        this.memoryCards.setCardAsMatched(id, true)
        this.setState({
          pairsFound: this.state.pairsFound + 1,
          firstId: undefined,
          secondId: undefined,
          turnNo: this.state.turnNo + 1,
          numClicksWithinTurn: 0
        })
      } else {
        this.timeout = setTimeout(() => {
          this.clearCards(this.state.firstId, this.state.secondId)
        }, 1000)
      }
    }
  }

  onPlayAgain () {
    this.initGame()
    this.timeInterval = null
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.startGameData !== this.props.startGameData) {
      // this.setState({
      //   warning:
      //     'Attention vous avez déjà commencé une fois ce jeu, nous reprenons la ou vous en etiez'
      // })
    }

    if (prevState.startPresentation !== this.state.startPresentation) {
      if (this.state.startPresentation) {
        this.presentGame()
      }
    }
    const elmnt = document.querySelector('.flipped')
    if (elmnt) elmnt.scrollIntoView(false)
  }

  componentWillUpdate (nextProps, nextState) {
    if (
      nextState.cardFlipped !== this.state.cardFlipped &&
      this.state.cardFlipped
    ) {
      this.memoryCards.flipCard(this.state.cardFlipped, false)
    }

    if (nextState.presentEnded !== this.state.presentEnded) {
      if (nextState.presentEnded) {
        this.timeInterval = setInterval(this.tick.bind(this), 1000)

        this.props.onStart()
        scroll.animateScroll.scrollToTop()
      }
    }

    if (nextState.pairsFound !== this.state.pairsFound) {
      if (nextState.pairsFound === this.memoryCards.imagesGames.length) {
        clearInterval(this.timeInterval)
        this.setState({ startPresentation: false })
        this.props.onEnd(this.state.turnNo + 1)
      }
    }

    if (nextProps.challenge !== this.props.challenge) {
      if (
        this.state.pairsFound === this.memoryCards.imagesGames.length &&
        nextProps.challenge.nbrPlayed < nextProps.challenge.nbrTry
      ) {
        // this.setState({
        //   displayNewGameButton: true
        // })
      } else {
        // this.setState({
        //   displayNewGameButton: false
        // })
      }
    }
  }

  componentWillUnmount () {
    clearInterval(this.timeInterval)
  }

  gameResult = challenge => {
    return (
      <h6 className='game-result-text live'>
        Bravo ! Vous avez trouvé toutes les paires en {this.state.turnNo} coups
      </h6>
    )
  }

  bestResult = () => {
    return (
      <h6 className='game-result-text'>
        Bravo ! <br />
        Votre meilleur score : Vous avez trouvé toutes les paires en{' '}
        {this.props.challenge.scoreMin} coups
      </h6>
    )
  }

  render () {
    let cardViews = this.getCardViews()

    const { challenge } = this.props

    return (
      <div className='Game wrapper-content center'>
        {challenge && challenge.nbrPlayed < challenge.nbrTry ? (
          <>
            <p>
              Pour gagner l’expérience de tes rêves, voici un jeu de mémoire :
              trouve toutes les paires le plus rapidement possible !{' '}
              <small style={{ display: 'block' }}>
                En cliquant sur "Commencer une partie" nous allons vous devoiler
                les cartes une par une, memorisez-les ! La dernière carte
                dévoilée commencera automatiquement le jeu
              </small>
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '20px auto 40px',
                width: 650
              }}
            >
              {!this.state.startPresentation && (
                <button
                  style={{ margin: '0 20px 0 0' }}
                  className='btn-play'
                  onClick={() => {
                    this.onPlayAgain()
                    this.setState({ startPresentation: true })
                  }}
                >
                  Commencer une partie
                </button>
              )}
              <>
                {this.state.secondsElapsed > 0 && (
                  <Timer
                    style={{ margin: '0' }}
                    time={this.state.secondsElapsed}
                  />
                )}
              </>
            </div>

            {this.state.pairsFound === this.memoryCards.imagesGames.length &&
              this.gameResult(challenge)}

            <div
              className='infos'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <span
                className={cx({
                  lastTry: challenge.nbrTry - challenge.nbrPlayed === 1
                })}
              >
                Essai(s) restant(s) :{' '}
                <strong> {challenge.nbrTry - challenge.nbrPlayed}</strong>
              </span>
              <span>
                Nombre de clic(s) : <strong>{this.state.turnNo}</strong>
              </span>
            </div>

            <div className='CardContainer'>{cardViews}</div>
          </>
        ) : challenge === null || challenge.nbrTry === 0 ? (
          <Redirect to={`/payment/${this.props.id}`} />
        ) : (
          <>
            {this.bestResult()}
            <p style={{ fontWeight: 'normal' }}>
              Restez attentif à vos mails pour savoir si vous êtes le grand
              gagnant ! Vous aurez la réponse le{' '}
              <strong style={{ fontWeight: 800 }}>
                {moment.unix(this.props.challengeEnd).format('DD MMMM', 'fr')}
              </strong>
            </p>
            <Link
              style={{ marginTop: 60 }}
              className='btn-participate'
              to={`/payment/${this.props.id}`}
            >
              JE RETENTE MA CHANCE
            </Link>
          </>
        )}
      </div>
    )
  }
}

export default Game
