import React from 'react'

function PageLoaderIndicator() {
    return (
        <div className="page-loader">
            <span>Chargement...</span>
        </div>
    )
}

export default PageLoaderIndicator
