import React from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames/bind'
import { Link } from 'react-router-dom'
import { AUTH_METHOD } from '../../../auth/base'
// import Axios from 'axios';
import moment from 'moment'
import UserService from '../../../api/services/UserService'
import LocalStorageInstance from '../../../helpers/LocalStorage'

const ComposantErreur = props => {
  return (
    <div className='error-message'>
      <span>{props.children}</span>
    </div>
  )
}

const Checkbox = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <div style={{ display: 'none' }}>
      <input
        name={name}
        id={id}
        type='checkbox'
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

const ComposantInput = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <div
      className={cx('form-group', {
        invalid: touched[field.name] && errors[field.name]
      })}
    >
      <label>
        {props.label} {field.name !== 'address' && '*'}
      </label>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative'
        }}
      >
        <input
          type='text'
          {...props}
          className='form-control'
          {...field}
          autoComplete='new-password'
        />
        {props.tooltip ? (
          <div
            onClick={() => props.displaytooltip(field.name)}
            onMouseEnter={() => props.displaytooltip(field.name)}
            onMouseLeave={() => props.displaytooltip(field.name)}
            className='tooltip_container'
            style={{ position: 'relative' }}
          >
            <i className='info_tooltip'>i</i>
            <span
              className={cx({
                displayed:
                  props.currenttooltip && props.currenttooltip === field.name
              })}
            >
              {props.tooltip}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

function Register (props) {
  const [currenttooltip, setCurrentTooltip] = React.useState(null)

  React.useEffect(() => {
    return () => {
      LocalStorageInstance.remove('gameId')
    }
  }, [])

  React.useEffect(
    () => {
      return () => {}
    },
    [props.authContext]
  )

  const registerSchema = Yup.object().shape({
    email: Yup.string()
      .required('Champs obligatoire')
      .email('Identifiant/email invalide.'),
    lastname: Yup.string().required('Champs obligatoire'),
    firstname: Yup.string().required('Champs obligatoire'),
    birthday: Yup.string()
      .required('Champs obligatoire')
      .matches(
        /^\s*(3[01]|[12][0-9]|0?[1-9])\/(1[012]|0?[1-9])\/((?:19|20)\d{2})\s*$/,
        {
          message: 'Format invalide (DD/MM/YYYY)',
          excludeEmptyString: true
        }
      )
      .test('DOB', 'Vous devez être majeur pour pouvoir participer.', value => {
        if (value !== undefined) {
          value = value.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3-$2-$1')
          return moment().diff(value, 'years') >= 18
        }

        return false
      }),
    // address: Yup.string().required('Champs obligatoire'),
    cp: Yup.string()
      .nullable()
      .required('Champs obligatoire')
      .matches(/^(([0-8][0-9])|(9[0-5])|(2[ab]))[0-9]{3}$/, {
        message:
          'Oups... le jeu est réservé aux habitants de la France Métropolitaine !',
        excludeEmptyString: true
      }),
    city: Yup.string()
      .nullable()
      .required('Champs obligatoire'),
    phone: Yup.string()
      .nullable()
      .required('Champs obligatoire')
      .matches(/^((\+)33|0|0033)[1-9](\d{2}){4}$/, {
        message: 'Numéro invalide 10 chiffres attendues',
        excludeEmptyString: true
      }),
    plainPassword:
      !props.edit &&
      Yup.string()
        .required('Champs obligatoire')
        .test(
          'regex',
          'Mot de passe invalide, doit contenir au minimum 8 caractères, 1 majuscule, 1 minuscule et 1 nombre .',
          val => {
            let regExp = new RegExp(
              '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
            )
            return regExp.test(val)
          }
        ),
    cgv: Yup.bool().oneOf([true], 'Vous devez accepter les CGV')
  })

  const submit = (values, actions) => {
    // actions.setSubmitting(false)

    const valuesSerialized = {
      ...values,
      birthday: values.birthday.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3-$2-$1')
    }

    delete valuesSerialized['cgv']

    if (props.from && props.from === 'payment') {
      UserService.register(valuesSerialized)
        .then(() => {
          UserService.login({
            username: values.email,
            password: values.plainPassword
          })
            .then(response => {
              actions.setSubmitting(false)
              LocalStorageInstance.store('authUser', response.token)
              props.authContext.setAuth(true)
              props.onRegistered()
            })
            .catch(error => {
              actions.setSubmitting(false)
              props.authContext.setSuccess(null)
              props.authContext.setError(error)
            })
        })
        .catch(error => {
          actions.setSubmitting(false)
          props.authContext.setSuccess(null)
          props.authContext.setError(error.data.errors)
        })
    } else {
      props.authContext.call(AUTH_METHOD.REGISTER, {
        valuesSerialized,
        actions
      })
    }
  }

  const edit = (values, actions) => {
    const valuesSerialized = {
      ...values,
      birthday: values.birthday.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3-$2-$1')
    }

    UserService.updateProfile(valuesSerialized)
      .then(() => {
        actions.setSubmitting(false)
        props.onProfileUpdated({
          type: 'success',
          message: 'Profil mis à jour'
        })
      })
      .catch(() => {
        actions.setSubmitting(false)

        props.onProfileUpdated({
          type: 'error',
          message: 'Echec de la mise à jour du profil'
        })
      })
  }

  const displaytooltip = tooltip => {
    currenttooltip === tooltip
      ? setCurrentTooltip(null)
      : setCurrentTooltip(tooltip)
  }

  return (
    <Formik
      onSubmit={props.edit ? edit : submit}
      initialValues={{
        lastname: props.user ? props.user.lastname : '',
        firstname: props.user ? props.user.firstname : '',
        birthday:
          props.user && props.user.birthday
            ? moment(props.user.birthday).format('DD/MM/YYYY')
            : '',
        address: props.user ? props.user.address : '',
        cp: props.user ? props.user.cp : '',
        city: props.user ? props.user.city : '',
        email: props.user ? props.user.email : '',
        phone: props.user ? props.user.phone : '',
        plainPassword: props.user ? props.user.plainPassword : '',
        cgv: !!props.user
      }}
      validationSchema={registerSchema}
      validateOnChange
    >
      {({ handleSubmit, isSubmitting }) => (
        <div
          style={{
            padding: '0 20px'
          }}
        >
          <div className='register-form'>
            <div className='wrapper-content center'>
              {props.from === undefined && !props.edit && <h2>S'inscrire</h2>}
              <form onSubmit={handleSubmit}>
                <div className='form-row'>
                  <Field
                    edit={props.edit}
                    user={props.user}
                    name='lastname'
                    label='Nom'
                    component={ComposantInput}
                  />
                  <ErrorMessage name='lastname' component={ComposantErreur} />
                </div>

                <div className='form-row'>
                  <Field
                    edit={props.edit}
                    user={props.user}
                    name='firstname'
                    label='Prénom'
                    component={ComposantInput}
                  />
                  <ErrorMessage name='firstname' component={ComposantErreur} />
                </div>

                <div className='form-row'>
                  <Field
                    edit={props.edit}
                    placeholder='31/10/1978'
                    name='birthday'
                    currenttooltip={currenttooltip}
                    displaytooltip={tooltip => displaytooltip(tooltip)}
                    tooltip='Il faut être majeur pour pouvoir participer aux jeux FORmeDABLE.'
                    label='Date de naissance'
                    component={ComposantInput}
                  />
                  <ErrorMessage name='birthday' component={ComposantErreur} />
                </div>

                <div className='form-row'>
                  <Field
                    edit={props.edit}
                    name='address'
                    label='Adresse'
                    component={ComposantInput}
                  />
                  <ErrorMessage name='address' component={ComposantErreur} />
                </div>

                <div className='form-row flexCol'>
                  <span>
                    <Field
                      edit={props.edit}
                      currenttooltip={currenttooltip}
                      name='cp'
                      displaytooltip={tooltip => displaytooltip(tooltip)}
                      tooltip='Jeux réservés aux personnes habitant en France métropolitaine.'
                      label='Code postal'
                      component={ComposantInput}
                    />
                    <ErrorMessage name='cp' component={ComposantErreur} />
                  </span>
                  <span>
                    <Field
                      edit={props.edit}
                      name='city'
                      label='Ville'
                      component={ComposantInput}
                    />
                    <ErrorMessage name='city' component={ComposantErreur} />
                  </span>
                </div>

                <div className='form-row'>
                  <Field
                    edit={props.edit}
                    type='email'
                    name='email'
                    label='Email'
                    component={ComposantInput}
                    currenttooltip={currenttooltip}
                    displaytooltip={tooltip => displaytooltip(tooltip)}
                    tooltip='Pour être contacté si on est le grand gagnant'
                  />
                  <ErrorMessage name='email' component={ComposantErreur} />
                </div>

                <div className='form-row'>
                  <Field
                    edit={props.edit}
                    type='password'
                    name='plainPassword'
                    label='Mot de passe'
                    component={ComposantInput}
                    currenttooltip={currenttooltip}
                    displaytooltip={tooltip => displaytooltip(tooltip)}
                    tooltip='Minimum 8 caractères.'
                    placeholder={props.edit && 'xxxxxxxxxx'}
                  />
                  <ErrorMessage
                    name='plainPassword'
                    component={ComposantErreur}
                  />
                </div>

                <div className='form-row'>
                  <Field
                    edit={props.edit}
                    name='phone'
                    label='Téléphone'
                    component={ComposantInput}
                    currenttooltip={currenttooltip}
                    displaytooltip={tooltip => displaytooltip(tooltip)}
                    tooltip='Plus facile pour l’équipe de FORmeDABLE de contacter le gagnant !'
                  />
                  <ErrorMessage name='phone' component={ComposantErreur} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '0 auto 20px'
                  }}
                >
                  <span
                    onClick={() => document.querySelector('#cgv').click()}
                    style={{
                      width: 20,
                      height: 20,
                      border: '1px solid #4C428E',
                      verticalAlign: 'middle',
                      cursor: 'pointer',
                      marginRight: 5,
                      display: props.edit ? 'none' : 'flex'
                    }}
                  >
                    {document.querySelector('#cgv') &&
                      document.querySelector('#cgv').checked && (
                      <i
                        style={{
                          width: 12,
                          height: 12,
                          background: '#4C428E',
                          display: 'block',
                          margin: '0 auto',
                          top: 3,
                          position: 'relative'
                        }}
                      />
                    )}
                  </span>
                  <label
                    htmlFor='cgv'
                    style={{
                      maxWidth: '85%',
                      display: props.edit ? 'none' : 'flex'
                    }}
                  >
                    <Field component={Checkbox} name='cgv' id='cgv' />
                    J’accepte les
                    <Link
                      target='_blank'
                      style={{ color: '#ec6466', margin: '0 5px' }}
                      to='/page/cgu'
                    >
                      CGU{' '}
                    </Link>{' '}
                    et le
                    <Link
                      target='_blank'
                      style={{ color: '#ec6466', margin: '0 5px' }}
                      to='/page/comment-ca-marche'
                    >
                      {' '}
                      Règlement
                    </Link>
                  </label>
                </div>
                <span
                  style={{
                    display: props.edit ? 'none' : 'flex',
                    justifyContent: 'center',
                    padding: '5px 0 0 10px'
                  }}
                >
                  <ErrorMessage name='cgv' component={ComposantErreur} />
                </span>

                <button
                  type='submit'
                  className='btn-participate'
                  disabled={isSubmitting}
                >
                  {props.edit ? 'je modifie mes infos' : 'continuer'}
                </button>

                <em
                      style={{
                        fontFamily: 'Kalam, sans-serif',
                        fontSize: 14,
                        textAlign: 'left'
                      }}
                    >
                      Vos Données seront traitées par Prisma Media, titulaire de
                      la marque FORMEDABLE, aux fins de traiter votre demande.
                      Elles seront conservées pendant la durée nécessaire à
                      cette fin, et seront accessibles aux personnels en charge
                      de Formedable. Pour toute information complémentaire, vous
                      pouvez consulter la{' '}
                      <Link
                        style={{ color: '#ec6466' }}
                        to='/page/politique-de-confidentialite'
                      >
                        Politique de confidentialité.{' '}
                      </Link>
                      Conformément à la réglementation en vigueur, vous pouvez
                      exercer vos droits d'accès, de rectification,
                      d'opposition, de suppression, de limitation du traitement,
                      et à la portabilité des Données en vous adressant à {` `}
                      <a
                        style={{ color: '#ec6466' }}
                        href='mailto:dpo@prismamedia.com'
                      >
                        dpo@prismamedia.com
                      </a>
                      .
                    </em>

                {!props.edit &&
                  props.authContext.error !== null &&
                  !props.authContext.error.success && (
                  <p
                    style={{
                      top: 'inherit',
                      textAlign: 'center',
                      marginTop: 20
                    }}
                    className='error-message'
                  >
                      Désolée cet email existe déjà
                  </p>
                )}

                {props.edit && (
                  <span
                    className='form_link text-center'
                    onClick={() => props.authContext.call(AUTH_METHOD.LOGOUT)}
                  >
                    Je me déconnecte
                  </span>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default Register
