import * as React from 'react'
import webShare from 'react-web-share-api'

const renderShareSvg = () => {
  return (
    <svg
      width='23'
      height='20'
      viewBox='0 0 23 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 0V5.6C2.8 5.6 0 11.34 0 19.6C1.456 14.056 5.6 11.2 11.2 11.2H14V16.8L22.4 7.9632L14 0Z'
        fill='#EC6466'
      />
    </svg>
  )
}

const Sharer = ({ share, isSupported, style, url }) =>
  isSupported ? (
    <a href onClick={share} style={style}>
      <span>{renderShareSvg()}</span>
    </a>
  ) : null
export default webShare()(Sharer)
