import React, { useState } from 'react'
import cx from 'classnames/bind'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import UserService from '../../../api/services/UserService'
import { withRouter } from 'react-router-dom'
const ComposantErreur = props => {
  return (
    <div className='error-message'>
      <span>{props.children}</span>
    </div>
  )
}

const ComposantInput = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <div className={cx('form-group', { invalid: errors[field.name] })}>
      <label> {props.label}* </label>
      <input
        type='text'
        {...props}
        className='form-control'
        {...field}
        autoComplete='off'
      />
    </div>
  )
}

function ResetPassword (props) {
  const query = new URLSearchParams(props.location.search)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const submit = (values, actions) => {
    // actions.setSubmitting(false)
    const data = {
      plainPassword: values.password,
      token: query.get('token')
    }

    UserService.confirmResetPassword(data)
      .then(response => {
        if (response.success) {
          actions.setSubmitting(false)
          setSuccess('Votre mot de passe a été mis à jour')
          setError(null)
        } else {
          actions.setSubmitting(false)
          setError(
            "La modification de votre de passe n'est plus disponible, veuillez refaire une demande, vous avez ensuite 24h pour effectuer le changement."
          )
          setSuccess(null)
        }
      })
      .catch(() => {
        actions.setSubmitting(false)
        setError(
          "La modification de votre de passe n'est plus disponible, veuillez refaire une demande, vous avez ensuite 24h pour effectuer le changement."
        )
      })
  }

  const userSchema = Yup.object().shape({
    password: Yup.string().required('Champs obligatoire'),
    password2: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Mot de passe non identique'
    )
  })

  return (
    <div className='login-wrapper'>
      <div className='wrapper-content center'>
        <h2>Modification de votre mot de passe</h2>
        <Formik
          onSubmit={submit}
          initialValues={{ password: '', password2: '' }}
          validationSchema={userSchema}
          validateOnChange
        >
          {({ handleSubmit, isSubmitting, actions }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name='password'
                type='password'
                label='Nouveau mot de passe'
                component={ComposantInput}
              />
              <ErrorMessage name='password' component={ComposantErreur} />

              <Field
                name='password2'
                type='password'
                label='Confirmation'
                component={ComposantInput}
              />
              <ErrorMessage name='password2' component={ComposantErreur} />
              <button
                type='submit'
                className='btn-participate'
                disabled={isSubmitting}
              >
                Je valide
              </button>
              {error !== null && (
                <p
                  style={{
                    top: 'inherit',
                    textAlign: 'center',
                    marginTop: 20
                  }}
                  className='error-message'
                >
                  {error}
                </p>
              )}

              {success !== null && (
                <p
                  style={{
                    top: 'inherit',
                    textAlign: 'center',
                    marginTop: 20,
                    color: '#4C428E'
                  }}
                >
                  {success}
                </p>
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default withRouter(ResetPassword)
