import React, { Component } from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames/bind'
import { Link } from 'react-router-dom'
import { AUTH_METHOD } from '../../../auth/base'
import { AuthContext } from '../../../context/AuthContext'

const ComposantErreur = props => {
  return (
    <div className='error-message'>
      <span>{props.children}</span>
    </div>
  )
}

const ComposantInput = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <div className={cx('form-group', { invalid: errors[field.name] })}>
      <label> {props.label} </label>
      <input type='text' {...props} className='form-control' {...field} />
    </div>
  )
}

class ForgotPassword extends Component {
  static contextType = AuthContext

  componentWillUnmount () {
    // this.context.setError(null)
    // this.context.setSuccess(null)
  }

  submit = (values, actions) => {
    // actions.setSubmitting(false)
    this.props.authContext.call(AUTH_METHOD.FORGOT_PASSWORD, {
      values,
      actions
    })
  }

  userSchema = Yup.object().shape({
    email: Yup.string()
      .required('Champs obligatoire')
      .email("L'email doit être valide")
  })

  render () {
    return (
      <div className='login-wrapper'>
        <div className='wrapper-content center'>
          <h2>
            Mot de passe oublié?
            <span>
              Entrez votre email pour lancer la procédure de changement de mot
              de passe
            </span>
          </h2>
          <Formik
            onSubmit={this.submit}
            initialValues={{ email: '' }}
            validationSchema={this.userSchema}
            validateOnChange
          >
            {({ handleSubmit, isSubmitting }) => (
              <form
                onSubmit={handleSubmit}
                className='w-50 bg-white border p-5 d-flex flex-column'
              >
                <Field name='email' label='Email' component={ComposantInput} />
                <ErrorMessage name='email' component={ComposantErreur} />
                <button
                  type='submit'
                  className='btn-participate'
                  disabled={isSubmitting}
                >
                  Envoyer
                </button>

                {this.context.error !== null && !this.context.error.success && (
                  <p
                    style={{
                      top: 'inherit',
                      textAlign: 'center',
                      marginTop: 20
                    }}
                    className='error-message'
                  >
                    Désolé cet email n'existe pas
                  </p>
                )}

                {this.context.success !== null && this.context.success.success && (
                  <p
                    style={{
                      top: 'inherit',
                      textAlign: 'center',
                      marginTop: 20,
                      color: '#4c428e'
                    }}
                    className='error-message'
                  >
                    Nous venons de vous envoyer un email pour modifier votre mot
                    de passe
                  </p>
                )}

                <span className='form_link text-center'>
                  <Link to='/auth/login'>Connexion</Link>
                </span>
              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default ForgotPassword
