import React, { useEffect } from 'react'
import PageHeader from '../shared/PageHeader'
// import HeaderHomeImg from '../../assets/images/img-header-home.jpg'
import ChallengeItem from '../shared/ChallengeItem'
import vectorSponsor from '../../assets/images/vector-sponsor.svg'
import BlockHowItWork from '../shared/BlockHowItWork'
import { fetchHome, fetchBanner } from '../../stores/home/home.actions'
import { connect } from 'react-redux'
import {
  HomeDataSelector,
  homeIsLoadingSelector
} from '../../stores/home/home.selectors'
import PageLoaderIndicator from '../shared/PageLoaderIndicator'
import { Helmet } from 'react-helmet'
import shapeToCome from '../../assets/images/light-purple-up.svg'
import '../../stores/home/index'

function Home (props) {
  useEffect(() => {
    props.fetchHome();
    return () => {}
  }, [])

  return !props.loading && props.data ? (
    <section>
      <Helmet
        title='Soyez égoïste, pensez aux autres !'
        meta={[
          {
            name: 'description',
            content: ''
          },
          {
            property: 'og:title',
            content: 'formedable, Soyez égoïste, pensez aux autres !'
          },
          {
            property: 'og:site_name',
            content: 'formedable'
          },
          { property: 'og:type', content: 'website' },
          {
            property: 'og:url',
            content: `https://${window.location.hostname}`
          },
          {
            property: 'og:description',
            content: ''
          },
          {
            property: 'og:image',
            content: ''
          },
          {
            property: 'og:image:width',
            content: ''
          },
          {
            property: 'og:image:height',
            content: ''
          },
          { name: 'twitter:title', content: 'Formedable' },
          {
            name: 'twitter:image',
            content: ''
          }
        ]}
      />
        { props.data.banner && (
                <div className={"home-pre-video-container"}>
                    <img
                        src={ props.data.banner }
                        alt={"Bandeau de présentation"}/>
                </div>
            )
        }

      <h3 className='home-headline'>
        Soyez égoïste, <span> pensez aux autres !</span>{' '}
      </h3>
      {/* { !isDesktop && */}
      <PageHeader
        data={{
          contentType: 'video',
          videoId: props.data.video
        }}
      />
      {/* } */}

      <section
        className='home'
        style={{
          marginBottom: 110
        }}
      >
        <div className='wrapper-content'>
          <h2>
            Nos EXPÉRIENCES <span>du moment</span>
          </h2>
          <div className='wrap-container'>
            {props.data.challenges.current.map((data, key) => (
              <ChallengeItem
                key={data.id}
                data={data}
                time={data.date_end}
                now
              />
            ))}
          </div>
        </div>
      </section>

      <img
        className='shape-come'
        src={shapeToCome}
        alt='shape'
        style={{ width: '100%' }}
      />

      <section
        className='home coming'
        style={{
          marginTop: -4,
          // marginBottom: 20,
          background: '#F6F6FC',
          paddingTop: 75,
          paddingBottom: 180
        }}
      >
        <div className='wrapper-content'>
          <h2>
            les EXPÉRIENCES <span>à venir</span>
          </h2>
          <div className='wrap-container'>
            {props.data.challenges.next.map((data, key) => (
              <ChallengeItem
                key={data.id}
                data={data}
                time={data.date_start}
                now={false}
              />
            ))}
          </div>
        </div>
      </section>

      <BlockHowItWork
        style={{
          position: 'relative',
          marginTop: -100
        }}
        customWidth={{
          width: 'calc(100% + 20px)'
        }}
      />

      <section className='home' style={{ marginTop: 100 }}>
        <div className='wrapper-content'>
          <h2 style={{ color: '#4C428E' }}>
            VOUS LES <span>AIDEZ</span>
          </h2>
          <div className='sponsors-container'>
            {props.data.associations.map(
              (association, index) =>
                association.logo && (
                  <div
                    key={index}
                    className='sponsor'
                  >
                    <img
                      src={association.logo.image}
                      alt='logo'
                      title={association.name}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </section>

      <section className='home' style={{ marginTop: 60 }}>
        <div className='wrapper-content'>
          <h2 style={{ color: '#4C428E' }}>
            ILS NOUS <span>SOUTIENNENT</span>
          </h2>
          <div className='sponsors-container'>
            {props.data.partners.map(
              (partner, index) =>
                partner.logo && (
                  <div
                    key={index}
                    className='sponsor'
                    style={{ backgroundImage: `url( ${vectorSponsor}) ` }}
                  >
                    <img
                      src={partner.logo.image}
                      alt='logo'
                      title={partner.name}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </section>
    </section>
  ) : (
    <PageLoaderIndicator />
  )
}

export default connect(
  state => ({
    data: HomeDataSelector(state),
    loading: homeIsLoadingSelector(state)
  }),
  { fetchHome }
)(Home)
