import React, { useContext } from 'react'
import IconLost from '../../../assets/images/icon-lost.svg'
import IconWin from '../../../assets/images/icon-win.svg'
import IconClose from '../../../assets/images/icon_close.svg'
import { Link } from 'react-router-dom'
import { ModalContext } from '../../../context/ModalContext'

function Results (props) {
  const [dataModal, setDataModal] = useContext(ModalContext)

  return (
    <>
      <span
        onClick={() => setDataModal({ ...dataModal, display: false })}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer'
        }}
      >
        <img src={IconClose} alt='close' />
      </span>
      <img
        src={props.challenge && props.challenge.isWinner ? IconWin : IconLost}
        alt='perdue'
      />
      <h2>
        {props.challenge && props.challenge.isWinner
          ? 'Félicitations, vous êtes le grand gagnant !'
          : 'Dommage, vous n’êtes pas le grand gagnant...'}
      </h2>
      <p>
        {props.challenge && props.challenge.isWinner
          ? 'Restez attentif à vos mails, nous revenons très vite vers vous. En attendant, pourquoi pas tenter une autre expérience ? '
          : 'Mais ce n’est que partie remise ! Parcourez nos autres expériences, et la prochaine fois sera la bonne. '}
      </p>

      <Link style={{ marginTop: 45 }} className='btn-participate' to='/'>
        JE tente une <br /> autre expérience
      </Link>
    </>
  )
}

export default Results
