import React from 'react'
import { Link } from 'react-router-dom'
export const NotFound = () => {
  return (
    <div style={{ margin: '0 auto' }} className='page login-wrapper'>
      <h1>Page non trouvée !</h1>
      <p>
        Nous somme désolés mais la page que vous essayez d'atteindre n'existe
        pas.
      </p>
      <p>
        <strong>
          Vous pouvez tout de même accéder à nos expériences en cliquant{' '}
        </strong>
        <Link to='/'>ici</Link>
      </p>
    </div>
  )
}
