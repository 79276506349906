import React from 'react'
import ReactPlayer from 'react-player'

function PageHeader (props) {
  React.useEffect(
    () => {
      if (
        ReactPlayer.canPlay(
          `https://www.youtube.com/watch?v=${props.data.videoId}`
        )
      ) {
      }
      return () => {}
    },
    [props.data]
  )
  return (
    <div className='page-header wrapper-content' style={{ margin: '0 auto' }}>
      {props.data.contentType === 'video' ? (
        <div className='video-wrapper'>
          <div className='video-container'>
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${props.data.videoId}`}
              muted
              playing
              volume={1}
              controls
              youtubeConfig={{ playerVars: { showinfo: 1 } }}
            />
          </div>
        </div>
      ) : (
        <img src={props.data.cover} alt='cover' />
      )}
    </div>
  )
}

export default PageHeader
