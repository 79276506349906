import apiService from '../../conf/api.custom'
import { API_ENDPOINT } from '../constants'

export default {
  getDetails: challengeId => {
    return apiService
      .get(`${API_ENDPOINT.CHALLENGES}/${challengeId}`)
      .then(response => response)
      .then(data => data)
  },
  featured: (challengeId = '') => {
    return apiService
      .get(`${API_ENDPOINT.CHALLENGES}/featured/${challengeId}`)
      .then(response => response)
      .then(data => data)
  },
  pay: (challengeId, values) => {
    return apiService
      .post(`${API_ENDPOINT.CHALLENGES}/pay/${challengeId}`, values)
      .then(response => response)
      .then(data => data)
  },
  canPlay: challengeId => {
    return apiService
      .get(`${API_ENDPOINT.CHALLENGES}/can_play/${challengeId}`)
      .then(response => response)
      .then(data => data)
  },
  startGame: (challengeId, values) => {
    return apiService
      .post(`${API_ENDPOINT.CHALLENGES}/start/${challengeId}`, values)
      .then(response => response)
      .then(data => data)
  },
  endGame: (challengeId, values) => {
    return apiService
      .post(`${API_ENDPOINT.CHALLENGES}/start/${challengeId}`, values)
      .then(response => response)
      .then(data => data)
  },
  participate: (challengeId, type, values = {}) => {
    return apiService
      .post(
        `${API_ENDPOINT.CHALLENGES}/participate/${challengeId}/${type}`,
        values
      )
      .then(response => response)
      .then(data => data)
  }
}
