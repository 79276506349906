// import apiService from "../../conf/api.custom";
import UserService from '../../api/services/UserService'
import axios from 'axios'
import apiService from '../../conf/api.custom'

export const REQUEST_USER = 'get user'
export const FETCH_USER = 'fetch user'
export const FETCH_USER_SUCCESS = 'fetch user success'
export const FETCH_USER_ERROR = 'fetch user error'

export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS'
export const FETCH_MENU_ERROR = 'FETCH_MENU_ERROR'

export const SET_REDIRECT_GAME = 'SET_REDIRECT_GAME'

export const requestUser = () => ({
  type: REQUEST_USER
})

export const fetchUserSucess = user => ({
  type: FETCH_USER_SUCCESS,
  user
})

export const fetchMenuSuccess = menu => ({
  type: FETCH_MENU_SUCCESS,
  menu
})



export const fetchMenu = () => dispatch => {
  return apiService
    .get('/menu')
    .then(response => {
      dispatch(fetchMenuSuccess(response.data))
    })
    .catch()
}

export const fetchUser = () => dispatch => {
  dispatch(requestUser())
  return axios.all([UserService.getMe(), UserService.getMeChallenges()]).then(
    axios.spread((profile, challenges) => {
      dispatch(fetchUserSucess([profile, challenges]))
    })
  )
}

export const fetchUserError = error => ({
  type: FETCH_USER_ERROR,
  error
})

export const setRedirectGame = url => ({
  type: SET_REDIRECT_GAME,
  url
})
