import React from 'react'
// import challengeImg from '../../assets/images/challenge-img.svg'
import { Link } from 'react-router-dom'
import clock from '../../assets/images/icon_clock.svg'
import euro from '../../assets/images/icon_euro.svg'
import moment from 'moment'
import { useInterval } from '../../hooks/useInterval'
import cx from 'classnames/bind'
import slugify from 'slugify'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'

function ChallengeItem (props) {
  const [timeLeft, setTimeLeft] = React.useState(null)
  const challengeRef = React.useRef()

  useInterval(() => {
    renderTimeLeft()
  }, 60000)

  React.useEffect(
    () => {
      renderTimeLeft()

      return () => {}
    },
    [timeLeft]
  )

  const renderTimeLeft = () => {
    const start = moment(Date.now())
    const end = moment.unix(props.time)

    const days = end.diff(start, 'days')
    const hours = end.diff(start, 'hours')
    const minutes = end.diff(start, 'minutes')

    if (days >= 1) {
      setTimeLeft(
        `${Math.round(days)} ${Math.round(days) === 1 ? `jour` : 'jours'} `
      )
    } else if (hours >= 1) {
      setTimeLeft(
        `${Math.round(hours)} ${Math.round(hours) === 1 ? `heure` : 'heures'} `
      )
    } else {
      if (minutes <= 0) {
        challengeRef.current && challengeRef.current.remove()
      }

      setTimeLeft(
        `${Math.round(minutes)} ${
          Math.round(minutes) === 1 ? `minute` : 'minutes'
        } `
      )
    }
  }

  return props.data ? (
    <div
      ref={challengeRef}
      className={cx({
        'challenge-item': true,
        fade: !props.now
      })}
    >
      <Link
        to={`/challenge/${slugify(props.data.title, {
          replacement: '_',
          remove: /[*+~.()'"!:@]/g,
          lower: true
        })}/${props.data.id}`}
      >
        <LazyLoadImage
          effect='opacity'
          alt={props.data.title}
          title={props.data.title}
          src={props.data.secondary_media && props.data.secondary_media.image} // use normal <img> attributes as props
          placeholder={null}
          placeholderSrc={
            props.data.secondary_media && props.data.secondary_media.image
          }
        />
      </Link>

      <div className='infos'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <span className='days-left'>
            <img src={clock} alt='clock' />
            {props.now ? 'IL RESTE' : 'Débute dans'} {timeLeft || ''}
          </span>
          <span className='days-left'>
            <img src={euro} alt='price' />
            Pour {props.data.price}&euro;
          </span>
        </div>
        <h2>
          <Link
            to={`/challenge/${slugify(props.data.title, {
              replacement: '_',
              remove: /[*+~.()'"!:@]/g,
              lower: true
            })}/${props.data.id}`}
          >
            {props.data.title}
          </Link>
        </h2>
        <div className='pilier'>
          {props.data.association && (
            <span>
              Pour soutenir {props.data.association && props.data.association.name}
            </span>
          )}
          {props.data.partner && (
            <span>
              Parrainé par {props.data.partner && props.data.partner.name}
            </span>
          )}
          {props.data.celebrity && (
            <span>
              Encouragé par {props.data.celebrity && props.data.celebrity.name}
            </span>
          )}
        </div>
      </div>
      {props.now && props.data && (
        <Link
          to={`/challenge/${slugify(props.data.title, {
            replacement: '_',
            remove: /[*+~.()'"!:@]/g,
            lower: true
          })}/${props.data.id}`}
          className='btn-participate'
        >
          <span>Je veux participer</span>
        </Link>
      )}
    </div>
  ) : null
}

export default ChallengeItem
