import ChallengesService from '../../../api/services/ChallengesService'
// import { fetchAssociationsError } from '../../association/store/associations.actions'

export const REQUEST_CHALLENGE = 'request challenge'
export const FETCH_CHALLENGE = 'fetch challenge'
export const FETCH_CHALLENGE_SUCCESS = 'fetch challenge success'
export const FETCH_CHALLENGE_ERROR = 'fetch challenge error'

// ASYNC ACTIONS

export const requestChallenge = () => ({
  type: REQUEST_CHALLENGE
})

export const fetchChallengeSucess = todos => ({
  type: FETCH_CHALLENGE_SUCCESS,
  todos
})

export const fetchChallengeError = error => ({
  type: FETCH_CHALLENGE_ERROR,
  error
})

export const resetChallenge = () => async dispatch => {
  dispatch(fetchChallengeSucess(null))
}

export const fetchChallenge = id => async dispatch => {
  dispatch(requestChallenge())

  ChallengesService.getDetails(id)
    .then(response => dispatch(fetchChallengeSucess(response.data)))
    .catch(error => {
          console.log(error)
          dispatch(
              fetchChallengeError(error.data.error.code)
          )
        }
    )
}
